import React, { Component } from 'react';
import { Card, SkeletonPage, Layout, SkeletonBodyText, SkeletonThumbnail, SkeletonDisplayText } from "@shopify/polaris";
export default class Loading extends Component {
    render() {
        const { layoutType } = this.props;
        return (
            <div>
                {layoutType === "Product_Review" ?
                    <div>
                        <SkeletonPage title="Customer Review" fullWidth={true} primaryAction>
                            <Layout>
                                <Layout.Section secondary>
                                    <Card title="OVERALL RATINGS">
                                        <Card.Section>
                                            <SkeletonBodyText lines={3} />
                                        </Card.Section>
                                        <Card.Section>
                                            <SkeletonBodyText lines={2} />
                                        </Card.Section>
                                        <Card.Section>
                                            <SkeletonBodyText lines={7} />
                                            <div className='mt-4 flex justify-content-between'>
                                                <SkeletonThumbnail size="medium" />
                                                <SkeletonThumbnail size="medium" />
                                                <SkeletonThumbnail size="medium" />
                                            </div>
                                        </Card.Section>
                                    </Card>
                                    
                                </Layout.Section>
                                <Layout.Section>
                                    <div>
                                        <p className='text-18 font-medium mb-3'>Reviews</p>
                                    </div>
                                    <Card sectioned>
                                        <div className='skeleton-padding-0'>
                                            <SkeletonPage fullWidth={true}></SkeletonPage>
                                        </div>
                                        <SkeletonBodyText lines={4}/>
                                    </Card>
                                    <Card sectioned>
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                        <SkeletonBodyText lines={7}/>
                                    </Card>
                                    <Card sectioned>
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                        <SkeletonBodyText lines={4}/>
                                    </Card>
                                </Layout.Section>
                                
                            </Layout>
                        </SkeletonPage>
                    </div>
                    :
                    layoutType === "Product_Review_Top" ? 
                    <div>
                        <SkeletonPage title="Customer Review" fullWidth={true} primaryAction>
                            <Card title="OVERALL RATINGS">
                            <Layout>
                                <Layout.Section secondary>
                                    <div className=''>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                    <Card.Section>
                                        <SkeletonBodyText lines={2} />
                                    </Card.Section>
                                    
                                </Layout.Section>
                                <Layout.Section>
                                    <div>
                                        {/* <p className='text-18 font-medium mb-3'>Reviews</p> */}
                                    </div>
                                        <SkeletonBodyText lines={4}/>
                                </Layout.Section>
                                
                            </Layout>
                            </Card>
                            <div className='mt-4'>
                                <Layout>
                                    <Layout.Section>
                                        <div>
                                            {/* <p className='text-18 font-medium mb-3'>Reviews</p> */}
                                        </div>
                                        <Card sectioned>
                                            <div className='skeleton-padding-0'>
                                                <SkeletonPage fullWidth={true}></SkeletonPage>
                                            </div>
                                            <SkeletonBodyText lines={4}/>
                                        </Card>
                                        <Card sectioned>
                                        <div className='skeleton-padding-0'>
                                            <SkeletonPage fullWidth={true}></SkeletonPage>
                                        </div>
                                            <SkeletonBodyText lines={7}/>
                                        </Card>
                                        <Card sectioned>
                                        <div className='skeleton-padding-0'>
                                            <SkeletonPage fullWidth={true}></SkeletonPage>
                                        </div>
                                            <SkeletonBodyText lines={4}/>
                                        </Card>
                                    </Layout.Section>
                                    
                                </Layout>
                            </div>
                        </SkeletonPage>
                    </div>
                    :
                    layoutType === "writeReview" ?
                    <div className='p-10'>
                        {/* <SkeletonPage fullWidth={true}> */}
                            <div>
                                <p className='text-18 font-medium mb-3'>Write Reviews</p>
                            </div>
                            <div className='mb-32'>
                                <div className='w-50 float-left'>
                                    <Card>
                                        <Card.Section>
                                            <SkeletonBodyText lines={1} />
                                        </Card.Section>
                                    </Card>
                                </div>
                                <div className='w-50 float-left'>
                                    <Card>
                                        <Card.Section>
                                            <SkeletonBodyText lines={1} />
                                        </Card.Section>
                                    </Card>
                                </div>
                            </div>
                            <div>
                                {/* <Card sectioned> */}
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                {/* </Card> */}
                            </div>
                            <div className='mb-4'>
                                <Card>
                                    <Card.Section>
                                        <SkeletonBodyText lines={1} />
                                    </Card.Section>
                                </Card>
                            </div>
                            <div className='mb-4'>
                                <Card >
                                    <Card.Section>
                                        <SkeletonBodyText lines={4} />
                                    </Card.Section>
                                </Card>
                            </div>
                            <div className='mb-4'>
                                <Card >
                                    <Card.Section>
                                        <SkeletonBodyText lines={4} />
                                    </Card.Section>
                                </Card>
                            </div>
                        {/* </SkeletonPage> */}
                    </div>
                    :
                    layoutType === "Customer_Review" ? 
                    <div className='p-10'>
                        <div>
                            <p className='text-18 font-medium mb-3'>Product Reviews &nbsp;&nbsp;&nbsp; Shop Reviews</p>

                        </div>
                        <div className='sorting-dropdown'>
                            {/* <Card sectioned> */}
                                <div className='skeleton-padding-0'>
                                    <SkeletonPage fullWidth={true}></SkeletonPage>
                                </div>
                            {/* </Card> */}
                        </div>
                        <div className='mb-4'>
                            <Card sectioned>
                                <div className='skeleton-padding-0'>
                                    <SkeletonPage fullWidth={true}></SkeletonPage>
                                </div>
                                <SkeletonBodyText lines={4}/>
                            </Card>
                        </div>
                        <div className='mb-4'>
                            <Card sectioned>
                                <div className='skeleton-padding-0'>
                                    <SkeletonPage fullWidth={true}></SkeletonPage>
                                </div>
                                <SkeletonBodyText lines={4}/>
                            </Card>
                        </div>
                        <div className='mb-4'>
                            <Card sectioned>
                                <div className='skeleton-padding-0'>
                                    <SkeletonPage fullWidth={true}></SkeletonPage>
                                </div>
                                <SkeletonBodyText lines={4}/>
                            </Card>
                        </div>
                    </div>
                    :
                    layoutType === "Review_Detail" ? 
                    <div className='p-10 grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 grid-cols-1'>
                        <div className='mr-2 card-100-per'>
                            <Card title="Product Preview">
                                {/* <SkeletonBodyText lines={1} /> */}
                                <div className='mt-4 px-8 lg:h-2/3'>
                                    <div className='block custom-skeleton-card'>
                                        <label>&nbsp;</label>
                                    </div>
                                </div>
                                <Card.Section>
                                    {/* <SkeletonBodyText lines={7} /> */}
                                    <div className='flex justify-content-between'>
                                        <SkeletonThumbnail size="medium" />
                                        <SkeletonThumbnail size="medium" />
                                        <SkeletonThumbnail size="medium" />
                                        <SkeletonThumbnail size="medium" />
                                    </div>
                                </Card.Section>
                            </Card>
                        </div>
                        <div className='ml-2 md:mt-4'>
                            {/* <p className='font-weight-600 mb-3'>Reviews</p> */}
                            <SkeletonBodyText lines={1}/>
                            <div className='mb-4 mt-3'>
                                <Card sectioned>
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                    <SkeletonBodyText lines={4}/>
                                </Card>
                            </div>
                            <div className='mb-4'>
                                <Card sectioned>
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                    <SkeletonBodyText lines={4}/>
                                </Card>
                            </div>
                        </div>
                    </div>
                    :
                    layoutType === "Review_Detail_No_Media" ? 
                    <div className='p-10 grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1'>
                       
                        <div className='ml-2 md:mt-4'>
                            {/* <p className='font-weight-600 mb-3'>Reviews</p> */}
                            <SkeletonBodyText lines={1}/>
                            <div className='mb-4 mt-3'>
                                <Card sectioned title="Product Preview">
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                    <SkeletonBodyText lines={4}/>
                                </Card>
                            </div>
                            <div className='mb-4'>
                                <Card sectioned>
                                    <div className='skeleton-padding-0'>
                                        <SkeletonPage fullWidth={true}></SkeletonPage>
                                    </div>
                                    <SkeletonBodyText lines={4}/>
                                </Card>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="h-screen relative">
                        <div className="absolute position-center">
                            <div class="boxes mb-32">
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <label className="text-20 text-nowrap">Please wait..</label>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
