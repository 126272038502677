export default class CommonConfig {

    static convertJSONValueToString = function (jsonobj) {
        const input = jsonobj;
        const json = JSON.stringify(input);
        const withStrings = JSON.parse(json, (key, val) => (
            typeof val !== 'object' && val !== null ? String(val) : val
        ));
        return withStrings;
    }

    static isEmpty = function (value) {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            if (typeof value === 'string') {
                return value.trim() === "";
            } else {
                return false;
            }
        }
    }
    static showErrorMsg = function (msg) {
        return (
            <span className="text-danger" style={{ color: "red" }}>
                <span className="field-validation-error">{msg}</span>
            </span>
        )
    }

    static findExtesionFromURL = function (urlPath) {
        const types = new Map([["jpg", "img"], ["jpeg", "img"], ["png", "img"], ["gif", "img"], ["mp4", "video"], ["3gp", "video"]])

        const url = new URL(urlPath)
        return url.pathname.split(".")[1]
    }

    // static isConvert = function (value) {
    //     if (value >= 1000000) {
    //         value = (value / 1000000).toFixed(1) + "M"
    //     }
    //     else if (value >= 1000) {
    //         // value = parseFloat((value / 1000)).toFixed(1) + "K";
    //         value = (value / 1000).toFixed(1) + "K";
    //     }
    //     return value;
    // };
    static isConvert = function (num) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol : "0";

    };

    static ConvertTimeZone = function (date, zone) {
        let convertDate = new Date(date);
        let zoneTime = null;
        let zoneTimeHr = null;
        let zoneTimemm = null;
        let TotalMinToAddOrSub = null;
        let offsetTime = null;
        if (zone) {
            let idx = zone.indexOf("+") || zone.indexOf("-");
            if (idx) {
                zoneTime = zone.slice(idx, 10);
                if (zoneTime) {
                    zoneTimeHr = zoneTime.slice(1, 3);
                    zoneTimemm = zoneTime.slice(4, 6);
                }
                if (zoneTimeHr && zoneTimemm) {
                    TotalMinToAddOrSub = Number(zoneTimeHr) * 60 + Number(zoneTimemm)
                }
            }
            if (zone.includes("+")) {
                offsetTime = new Date(this.dateFormatUsingDefaultJS(convertDate) + " " + this.ConvertMinToHHMM(convertDate.getHours() * 60 + convertDate.getMinutes() + TotalMinToAddOrSub));
            } else {
                offsetTime = new Date(this.dateFormatUsingDefaultJS(convertDate) + " " + this.ConvertMinToHHMM(convertDate.getHours() * 60 + convertDate.getMinutes() - TotalMinToAddOrSub));
            }
            // console.log("t", new Date(this.dateFormatUsingDefaultJS(convertDate) + " " + this.ConvertMinToHHMM(convertDate.getHours() * 60 + convertDate.getMinutes() + TotalMinToAddOrSub)))
            return offsetTime;

            /*-- OLD Snippet--*/
            // var format = 'YYYY/MM/DD HH:mm:ss ZZ';
            // return moment.tz(time, zone).format(format);
        }
    }
    static ConvertMinToHHMM = function (inputMinutes) {
        var totalMinutes = inputMinutes;

        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        return hours + ":" + minutes;
    }

    static dateFormatUsingDefaultJS(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('-');
    }

    static isObjectEmpty = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static generateVideoThumbnail = async function (url, seekTo = 0.0) {
        // console.log("getting video cover for file: ", file);
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', url);
            videoPlayer.crossOrigin = "Anonymous"
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    resolve(canvas.toDataURL("image/jpg"))
                    // ctx.canvas.toBlob(
                    //     blob => {
                    //         resolve(blob);
                    //         console.log("keval",blob)
                    //     },
                    //     "image/jpeg",
                    //     0.75 /* quality */
                    // );
                });
            });
        })
    }

    static findFileNameFromURL = function (urlPath) {
        var url = urlPath;
        var filename = url.substring(url.lastIndexOf('/') + 1);
        return filename;
    }

    static findExtesionFromURL = function (urlPath) {
        const types = new Map([["jpg", "img"], ["jpeg", "img"], ["png", "img"], ["gif", "img"], ["mp4", "video"], ["3gp", "video"]])

        const url = new URL(urlPath)
        return url.pathname.split(".")[1]
    }

    static convertImgToBase64 = async function (url) {
        return fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
    }

    static base64toFile = function (dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    // static filterTwoArray = function (data, filters) {
    //     return data.filter(e => {
    //         try {
    //             filters.forEach(o => {
    //                 Object.keys(o).forEach(key => {
    //                     if (e[key] !== o[key]) throw new 1;
    //                 });
    //             });

    //             return true;
    //         } catch (e) {
    //             return false;
    //         }
    //     });
    // }

    static getDatesBetweenDates = function (startDate, endDate) {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
            dates = [...dates, new Date(theDate)]
            theDate.setDate(theDate.getDate() + 1)
        }
        // dates = [...dates, endDate]
        dates.splice(0, 1)
        return dates
    }

    static addDaysToDates = function (date, noOfDays) {
        var theDate = date;
        theDate.setDate(theDate.getDate() + noOfDays);
        return theDate;
    }

    static dateFormat = {
        dateTime: 'MM/DD/YYYY hh:mm:ss A',
        dateOnly: 'MM/DD/YYYY',
        dateFirst: 'DD/MM/YYYY',
        forDatePicker: 'DD-MM-YYYY',
        yearOnly: 'YYYY',
        dbDateTime: 'YYYY-MM-DD HH:mm:ss',
        timeOnly: 'HH:mm'
    }

    static loginData = function () {
        if (localStorage.getItem('loginData')) {
            return JSON.parse(localStorage.getItem('loginData'));
        }
        else {
            return ""
        }
    }

    static getUserAccess = function (moduleName) {
        if (localStorage.getItem('loginData')) {
            let data = JSON.parse(localStorage.getItem('loginData')).userModuleAccess;

            let moduleAccess = data.find(x => x.MenuKey === moduleName);

            return moduleAccess

        } else {
            return ""
        }
    }

    static filterCaseInsensitive = function (filter, row) {
        const id = filter.pivotId || filter.id;
        const content = row[id];

        if (typeof content !== 'undefined') {
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    }

    static ReadMoreLen = 400;

    static RegExp = {
        number: /^[0-9\b]+$/,
        onlyNumber: /[a-zA-Z~`!@#$%^&*()_+=-{}|:"<>?,;']+$/,
        onlyDecimal: /^[0-9]+(\.[0-9][0-9])?$/,
        phoneNumber: /^([0-9]+\s?)*$/g,
        // email : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/ig,
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        companyName: /[!@~`#$%^&*()_+\-=\]{};':"\\|,.<>?]/,
        zipCode: /^([a-zA-Z0-9]+\s?)*$/g,
        regExpNumber: /[0-9]/g,
        regExpUpperCase: /[A-Z]/g,
        regExpLowerCase: /[a-z]/g,
        phone: /^[0-9]{10}$/,
        regExpSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/g
    }

}