import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import rvwPro from "../../assets/img/Illustration.png";
import socialDefault from "../../assets/img/social-default.svg";
import cancel from "../../assets/img/Cancel-bg.svg";
import * as Constants from '../../utils/graphql_queries.js';
import { client,widgetSettingsShopURL } from '../../utils/config';
import moment from "moment";
import CommonConfig from '../../utils//constant';
import { Link } from 'react-router-dom';

const initialState = {
    primary_color: "",
    social_proof_widget: "",
    social_proof_widget_style: "",
    rating_social: "",
    review_title_social: "",
    review_body_social: "",
    reviewer_name_social: "",
    media1_social: "",
    created_at_social: "",
    initials : 'JD',
    timeZone: '',
}
export default class SocialProof extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        this.getSocialProofWidget(25, 1);
        this.getWedigetsAdminSettings();
    }



    getSocialProofWidget = (first, shop_id) => {

        var data = {
            first: first,
            shop_id: shop_id
        }
        client
            .query({
                query: Constants.SOCIAL_PROOF_WIDGET,
                variables: data,
            })
            .then(result => {
                if (result.data.WidgetSocialProofWidget.data.length > 0) {
                    var firstName = result.data.WidgetSocialProofWidget.data[0].reviewer_name.split(' ').slice(0, 1).join(' ');
                    var lastName = result.data.WidgetSocialProofWidget.data[0].reviewer_name.split(' ').slice(-1).join(' ');
                    var initials = firstName.charAt(0) + lastName.charAt(0);
                    this.setState({

                        rating_social: result.data.WidgetSocialProofWidget.data[0].rating,
                        review_title_social: result.data.WidgetSocialProofWidget.data[0].review_title,
                        review_body_social: result.data.WidgetSocialProofWidget.data[0].review_body,
                        reviewer_name_social: result.data.WidgetSocialProofWidget.data[0].reviewer_name,
                        media1_social: result.data.WidgetSocialProofWidget.data[0].media1,
                        created_at_social: result.data.WidgetSocialProofWidget.data[0].created_at,
                        title_social: result.data.WidgetSocialProofWidget.data[0].product.title,
                        handle_social: result.data.WidgetSocialProofWidget.data[0].product.handle,
                        initials: initials

                    })

                    
                }
            }).catch((error) => {
            });
    }
    getWedigetsAdminSettings = () => {
        fetch(widgetSettingsShopURL).then(res => { return res.json() }).then(result => {
            this.setState({

                product_widget: result[0].product_widget,
                widget_language: result[0].widget_language,
                widget_font: result[0].widget_font,
                progressbar_theme: result[0].progressbar_theme,
                primary_color: result[0].primary_color,
                rating_star_color: result[0].rating_star_color,
                background_color: result[0].background_color,
                write_review_button: result[0].write_review_button,
                reviewer_name: result[0].reviewer_name,
                review_publish_date: result[0].review_publish_date,
                reviewer_country_flag: result[0].reviewer_country_flag,
                customer_photo: result[0].customer_photo,
                share_button: result[0].share_button,
                hide_findshop_branding: result[0].hide_findshop_branding,
                card_widget: result[0].card_widget,
                title_widget: result[0].title_widget,
                title_widget_style: result[0].title_widget_style,
                floating_widget: result[0].floating_widget,
                page_widget: result[0].page_widget,

                max_review_display: result[0].max_review_display,
                hide_product_link: result[0].hide_product_link,
                hide_star_no_reviews: result[0].hide_star_no_reviews,
                widget_summary_bar: result[0].widget_summary_bar,
                reviewer_name_style: result[0].reviewer_name_style,
                frequent_mentions: result[0].frequent_mentions,
                display_customer_image_box: result[0].display_customer_image_box,
                floating_tab_font_color: result[0].floating_tab_font_color,
                floating_tab_background_color: result[0].floating_tab_background_color,

                floating_widget_position: result[0].floating_widget_position,



                social_proof_widget: result[0].social_proof_widget,
                social_proof_widget_style: result[0].social_proof_widget_style,
                social_proof_widget_delay: result[0].social_proof_widget_delay,
                social_proof_widget_position: result[0].social_proof_widget_position,
                timeZone: result[0].time_zone,

            })
        })
    }

    render() {

        const { timeZone, primary_color, social_proof_widget, social_proof_widget_style, rating_social, review_title_social, review_body_social, reviewer_name_social, media1_social, created_at_social,initials } = this.state;
        return (
            <div>{social_proof_widget === "1" ?
                <div>
                    {social_proof_widget_style === "horizontal" ?
                        <div className="shadow-lg rounded-xl max-w-xl p-4 bg-white dark:bg-gray-800 relative overflow-hidden">
                            <Link to="#" className="w-full h-full block">
                                <div className="w-full">
                                    <div className="flex items-center justify-between">
                                        <p className="text-gray-600 uppercase text-md font-medium mb-0">
                                            New review
                                </p>
                                        <span className="text-black-800 uppercase text-2xl font-light"><FontAwesomeIcon icon={faTimes} /></span>
                                    </div>
                                    <div className="flex items-center grid grid-cols-12 gap-6 mb-3">
                                        <div className="col-span-3 col-start-1">
                                            <img alt="profil" src={media1_social ?media1_social : socialDefault} className="object-cover" />
                                        </div>
                                        <div className="col-start-4 col-span-9">
                                            <div className="flex items-center mb-2">
                                                <FontAwesomeIcon icon={faStar} className="text-18" style={{ color: '#FF8800' }} />
                                                <div className="ml-2 text-nowrap">
                                                    <span className="text-3xl text-black-300 font-semibold">{rating_social}</span>&nbsp;<span className="text-3xl text-gray-300 font-semibold">/ 5</span>
                                                </div>
                                            </div>
                                            <label className="text-black-600 text-16 font-semibold mb-2" style={{ color: primary_color }}>{review_title_social}</label>
                                            <p style={{ fontSize: '13px', color: primary_color }} className="two-line-trancate text-gray-400" >
                                                {review_body_social}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between" >
                                        <div className=" flex items-center">
                                            
                                            <div>
                                                <span class="text-gray-700 font-medium text-16 flex items-center justify-center bg-gray-300 w-14 h-14 mr-2 rounded-full">{initials}</span>
                                            </div>
                                            <label className="font-medium mb-0 ml-3 text-black-400 text-xl" style={{ color: primary_color }}>{reviewer_name_social}</label>
                                            <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                            </span>
                                            <p className="text-gray-400 text-xl font-normal ml-3 text-center">
                                                {created_at_social ? moment(created_at_social).fromNow() : ""}
                                                {/* {created_at_social ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(created_at_social), timeZone)).fromNow() : '-'} */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> :
                        <div className="shadow-lg rounded-xl w-80 bg-white overflow-hidden">
                            <div className="relative">
                                <img alt="profil" src={media1_social ?media1_social : socialDefault} className="object-cover overflow-hidden w-full h-64" />
                                <div className="top-3 right-3 w-8 absolute">
                                    <img alt="profil" src={cancel} className="object-cover" />
                                    {/* <FontAwesomeIcon icon={faTimesCircle} className="text-gray-300 font-normal" /> */}
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faStar} style={{ color: '#FF8800' }} />
                                    <div className="ml-2 ">
                                        <span className="text-3xl text-black-300 font-semibold">{rating_social}</span>&nbsp;<span className="text-3xl text-gray-300 font-semibold">/ 5</span>
                                    </div>
                                </div>
                                <div >
                                    <label className="text-black-600 text-14 font-semibold mb-2" style={{ color: primary_color }}>{review_title_social}</label>
                                </div>
                                <div className=" flex items-center mt-3">
                                    <label className="font-medium mb-0 text-black-400 text-xl" style={{ color: primary_color }}>{reviewer_name_social}</label>
                                    <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                    </span>
                              <p className="text-gray-400 text-lg font-normal text-right ml-3">

                                        {created_at_social ? moment(created_at_social).fromNow() : ""}
                                        {/* {created_at_social ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(created_at_social), timeZone)).fromNow() : '-'} */}
                                    </p> 
                        </div>
                            </div>
                        </div>} </div> : null}
            </div>
        )
    }
}
