import React, { Component } from 'react'
import { faStar, faStarHalfAlt, faStarAndCrescent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cartIcon from '../../assets/img/card-icon.svg';
import shynrIcon from '../../assets/img/shynr.svg';
import Logo from '../../assets/img/shynr.png';
import rvwPro1 from "../../assets/img/image-1.png";
import * as Constants from '../../utils/graphql_queries.js';
import { client, widgetSettingsShopURL } from '../../utils/config';
import ReactStars from "react-rating-stars-component";
import { Stack, Thumbnail, Caption, DropZone } from '@shopify/polaris';
import CommonConfig from '../../utils/constant.js';
import moment from 'moment';
import ErrorMessage from '../ErrorMessage/errorMessage';
import ErrorNotFound from '../404Error/404Error';
import StarRatingComponent from 'react-star-rating-component';
import Loading from '../Loading/Loading';
import WebFont from 'webfontloader';
import ImgNotFound from "../../assets/img/company-Image.png";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const initialState = {
    order_id: "",
    order_at: "",
    product_image1: "",
    title: "",
    allow_skip_review: "",
    accept_store_reviews: "",
    allow_media_upload: "",
    allow_video_upload: "",
    logo: "",
    productsOrdered: [],
    StepCount: 0,
    ErrSteps: 0,
    validImageTypes: ['image/jpeg', 'image/png'],
    shop_average_rating: 0,
    shop_review_body: '',
    shop_review_title: '',
    reviewer_name: '',
    reviewer_email: "",
    reviewer_mobile: '',
    request_id: '',
    shop_id: '',
    progressWidth: 0,
    finalWidthPercentage: 0,
    IsValid: false,
    ProductID: '',
    IsSubmitEntered: false,
    pageloading: true,
    shop_name: '',
    ratingColor: '#11ff00',

    ProductReviewRatingErr: false,
    ProductReviewRatingErrText: '',
    ProductReviewTitleErr: false,
    ProductReviewTitleErrText: '',
    ProductReviewBodyErr: false,
    ProductReviewBodyErrText: '',


    shopAverageRatingErr: false,
    shopAverageRatingErrText: '',
    shopReviewBodyErr: false,
    shopReviewBodyErrText: '',
    shopReviewTitleErr: false,
    shopReviewTitleErrText: '',
    productsFileData: []
}
export default class productWriteReview extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = async () => {
        await this.validateURL();
    }

    validateURL = async () => {
        var data = {
            "url": window.location.href
        }

        await client
            .query({
                query: Constants.VALIDATE_URL,
                variables: data,
            })
            .then(async result => {
                if (result.data.ValidateUrl[0].is_url_valid === "true") {
                    var url = new URL(window.location.href);
                    var productid = url.searchParams.get("id");
                    if (!CommonConfig.isEmpty(productid)) {
                        await this.getOrderdetails(productid);
                        this.setState({ IsValid: true, ProductID: productid })
                    }
                }
                else {
                    var url = new URL(window.location.href);
                    var productid = url.searchParams.get("id");
                    if (!CommonConfig.isEmpty(productid)) {
                        this.getOrderdetails(productid);
                        this.setState({ IsValid: true, ProductID: productid })
                    }
                    // this.setState({pageloading : false})
                }
            }).catch((error) => {
            });
    }

    getOrderdetails = async (id) => {
        var data = {
            id: id
        }
        await client
            .query({
                query: Constants.REQUEST_REVIEW,
                variables: data,
            })
            .then(async result => {
                let response = await result.data;
                if (!CommonConfig.isObjectEmpty(response.RequestReview)) {
                    if (response.RequestReview.is_review_collected === "yes") {
                        this.props.history.push('/storefront/successmessage')
                    } else {
                        var WidthCount = response.RequestReview.product.length + 1;
                        if (response.RequestReview.setting_request_type.accept_store_reviews === "yes") {
                            WidthCount = WidthCount + 1;
                        }

                        var finalWidthPercentage = parseFloat(100 / WidthCount).toFixed(2);
                        this.setState({
                            progressWidth: finalWidthPercentage,
                            finalWidthPercentage: finalWidthPercentage,
                            productsOrdered: response.RequestReview.product.map((item) => ({
                                ...item,
                                product_reviews_rating: 0,
                                product_reviews_title: '',
                                product_reviews_body: '',
                                files: []
                            })),
                            shop_id: response.RequestReview.shop_id,
                            request_id: response.RequestReview.id,
                            order_id: response.RequestReview.order.display_order_id,
                            order_at: response.RequestReview.order.order_at,
                            reviewer_name: response.RequestReview.customer.name,
                            reviewer_email: response.RequestReview.customer.email,
                            reviewer_mobile: response.RequestReview.customer.mobile,
                            product_image1: response.RequestReview.product[0].product_image1,
                            title: response.RequestReview.product[0].title,
                            allow_skip_review: response.RequestReview.setting_request_type.allow_skip_review,
                            accept_store_reviews: response.RequestReview.setting_request_type.accept_store_reviews,
                            allow_media_upload: response.RequestReview.setting_request_type.allow_media_upload,
                            allow_video_upload: response.RequestReview.setting_request_type.allow_video_upload,
                            logo: response.RequestReview.shop_setting.logo,
                            shop_name: response.RequestReview.shop_setting.shop_name,
                            ratingColor: response.RequestReview.setting_widget.rating_star_color,
                            widget_font: response.RequestReview.widget_font,
                            widget_font_name: response.RequestReview.widget_font_name

                        })

                        WebFont.load({
                            google: {
                                families: [response.RequestReview.widget_font]
                            }
                        })
                    }
                    this.setState({ pageloading: false })
                } else {
                    this.setState({ pageloading: false })
                }
            }).catch((error) => {
            });
    }

    // shopVerifiedReview = () => {
    // this.uploadImage();
    // console.log(this.state.productsOrdered)
    // var data = {

    //     "request_id": "133",
    //     "product_reviews": [
    //         {
    //             "shop_id": "5",
    //             "product_id": "6147035693226",
    //             "product_title": this.state.product_title,
    //             "rating": this.state.product_reviews_rating,
    //             "review_title": this.state.product_review_title,
    //             "review_body": this.state.product_review_body,
    //             "media1": this.state.product_media1,
    //             "media2": this.state.product_media2,
    //             "media3": this.state.product_media3,
    //             "media4": this.state.product_media4,
    //         },
    //     ],
    //     "store_review": {
    //         "review_title": this.state.shop_review_title,
    //         "review_body": this.state.shop_review_body,
    //         "average_rating": this.state.shop_average_rating,
    //     },
    //     "reviewer_name": this.state.reviewer_name,
    //     "reviewer_email": this.state.reviewer_email,
    //     "reviewer_mobile": this.state.reviewer_mobile,
    // }



    // client
    //     .mutate({
    //         mutation: Constants.VERIFIED_REVIEW,
    //         variables: data,
    //     })
    //     .then(async result => {

    //     }).catch((error) => {
    //     });
    // }

    validationForProduct = () => {
        const { productsOrdered, StepCount } = this.state;
        let IsValid = true;

        for (let i = 0; i < productsOrdered.length; i++) {
            if (StepCount === i) {
                this.setState({ ErrSteps: i })
                if (productsOrdered[i].product_reviews_rating === 0) {
                    this.setState({ ProductReviewRatingErr: true, ProductReviewRatingErrText: 'Review rating is required' })
                    IsValid = false;
                } else {
                    this.setState({ ProductReviewRatingErr: false, ProductReviewRatingErrText: '' })
                }

                if (CommonConfig.isEmpty(productsOrdered[i].product_reviews_title)) {
                    this.setState({ ProductReviewTitleErr: true, ProductReviewTitleErrText: 'Review title is required' })
                    IsValid = false;
                } else {
                    this.setState({ ProductReviewTitleErr: false, ProductReviewTitleErrText: '' })
                }

                if (CommonConfig.isEmpty(productsOrdered[i].product_reviews_body)) {
                    this.setState({ ProductReviewBodyErr: true, ProductReviewBodyErrText: 'Review description is required' })
                    IsValid = false;
                } else {
                    this.setState({ ProductReviewBodyErr: false, ProductReviewBodyErrText: '' })
                }
            }
        }

        return IsValid;
    }

    validationForSHop = () => {
        const { StepCount, shop_average_rating, shop_review_body, shop_review_title, accept_store_reviews } = this.state;
        let IsValid = true;

        if (accept_store_reviews == "yes") {
            if (shop_average_rating === 0) {
                this.setState({ shopAverageRatingErr: true, shopAverageRatingErrText: 'Review rating is required' })
                IsValid = false;
            } else {
                this.setState({ shopAverageRatingErr: false, shopAverageRatingErrText: '' })
            }

            if (CommonConfig.isEmpty(shop_review_title)) {
                this.setState({ shopReviewTitleErr: true, shopReviewTitleErrText: 'Review title is required' })
                IsValid = false;
            } else {
                this.setState({ shopReviewTitleErr: false, shopReviewTitleErrText: '' })
            }

            if (CommonConfig.isEmpty(shop_review_body)) {
                this.setState({ shopReviewBodyErr: true, shopReviewBodyErrText: 'Review description is required' })
                IsValid = false;
            } else {
                this.setState({ shopReviewBodyErr: false, shopReviewBodyErrText: '' })
            }
        }
        else {
            IsValid = this.validationForProduct();
        }

        return IsValid;
    }

    submit = (tempProductArr) => {
        this.setState({ IsSubmitEntered: false })
        var maindata = {};
        if (this.state.accept_store_reviews == "yes") {
            maindata = {
                "request_id": this.state.request_id,
                "product_reviews": tempProductArr.length && tempProductArr.filter(x => x.product_reviews_title != "").length ? tempProductArr.filter(x => x.product_reviews_title != "").map(x => {
                    return {
                        "shop_id": this.state.shop_id,
                        "product_id": x.product_id,
                        "product_title": x.title,
                        "rating": x.product_reviews_rating,
                        "review_title": x.product_reviews_title,
                        "review_body": x.product_reviews_body,
                        "media1": x.files[0],
                        "media2": x.files[1],
                        "media3": x.files[2],
                        "media4": x.files[3],
                        "variant_id": x.variant_id,
                        "variant_title": x.variant_title
                    }
                }) : [],
                "store_review": {
                    "review_title": this.state.shop_review_title,
                    "review_body": this.state.shop_review_body,
                    "average_rating": this.state.shop_average_rating,
                },
                "reviewer_name": this.state.reviewer_name,
                "reviewer_email": this.state.reviewer_email,
                "reviewer_mobile": this.state.reviewer_mobile,
            }
        }
        else {
            maindata = {
                "request_id": this.state.request_id,
                "product_reviews": tempProductArr.length && tempProductArr.filter(x => x.product_reviews_title != "").length ? tempProductArr.filter(x => x.product_reviews_title != "").map(x => {
                    return {
                        "shop_id": this.state.shop_id,
                        "product_id": x.product_id,
                        "product_title": x.title,
                        "rating": x.product_reviews_rating,
                        "review_title": x.product_reviews_title,
                        "review_body": x.product_reviews_body,
                        "media1": x.files[0],
                        "media2": x.files[1],
                        "media3": x.files[2],
                        "media4": x.files[3],
                        "variant_id": x.variant_id,
                        "variant_title": x.variant_title
                    }
                }) : [],
                "reviewer_name": this.state.reviewer_name,
                "reviewer_email": this.state.reviewer_email,
                "reviewer_mobile": this.state.reviewer_mobile,
            }
        }
        client
            .mutate({
                mutation: Constants.VERIFIED_REVIEW,
                variables: CommonConfig.convertJSONValueToString(maindata),
            })
            .then(async result => {
                if (result.data.VerifiedProductReview.data_status === "success") {
                    this.props.history.push('/storefront/successmessage')
                    this.setState({ pageloading: false })
                } else {
                    this.setState({ pageloading: false })
                }
            }).catch((error) => {
            });
    }

    nextProductReview = (e) => {
        if (this.validationForProduct()) {
            this.setState({ StepCount: this.state.StepCount + 1, progressWidth: Number(this.state.progressWidth) + Number(this.state.finalWidthPercentage) })
        }
    }

    skipStep = (type) => {
        if (type === 'EveryProduct') {
            let TempProductOrder = this.state.productsOrdered;
            TempProductOrder[this.state.StepCount]['product_reviews_rating'] = 0;
            TempProductOrder[this.state.StepCount]['product_reviews_title'] = '';
            TempProductOrder[this.state.StepCount]['product_reviews_body'] = '';
            this.setState({
                StepCount: this.state.StepCount + 1, progressWidth: Number(this.state.progressWidth) + Number(this.state.finalWidthPercentage), ProductReviewRatingErr: false, ProductReviewRatingErrText: '', ProductReviewTitleErr: false, ProductReviewTitleErrText: '', ProductReviewBodyErr: false, ProductReviewBodyErrText: '', productsOrdered: TempProductOrder
            })
        } else {
            this.setState({ StepCount: this.state.StepCount + 1, progressWidth: Number(this.state.progressWidth) + Number(this.state.finalWidthPercentage), ProductReviewRatingErr: false, ProductReviewRatingErrText: '', ProductReviewTitleErr: false, ProductReviewTitleErrText: '', ProductReviewBodyErr: false, ProductReviewBodyErrText: '' })
        }

    }

    shopVerifiedReview = async () => {
        if (this.validationForSHop()) {
            this.setState({ pageloading: true })
            const { productsOrdered } = this.state;
            let tempProductArr = productsOrdered;
            for (let i = 0; i < productsOrdered.length; i++) {
                let files = productsOrdered[i].files.length ? productsOrdered[i].files.map(files => { return files.file }) : []
                if (files.length) {
                    // var data = {
                    //     file: [
                    //         productsOrdered[i].files[0],
                    //         productsOrdered[i].files[1],
                    //         productsOrdered[i].files[2],
                    //         productsOrdered[i].files[3]
                    //     ]
                    // }
                    // var formData = new FormData()
                    // formData.append('operations', JSON.stringify({ "query": "mutation UploadReviewImage($file: [Upload!]!) {\nUploadReviewImage(file: $file){\ndata_status\ndata_message\nmedia1\nmedia2\nmedia3\nmedia4\n}\n}", "variables": { "file": null }, "operationName": "UploadReviewImage" }))
                    // formData.append('map', JSON.stringify({ "0": ["variables.file"], "1": ["variables.file"], "2": ["variables.file"], "3": ["variables.file"] }))
                    // formData.append(0, data.file[0])
                    // formData.append(1, data.file[1])
                    // formData.append(2, data.file[2])
                    // formData.append(3, data.file[3])
                    // fetch(client.link.options.uri, {
                    //     method: 'POST',
                    //     header: {
                    //         'Access-Control-Allow-Origin': '*',
                    //         "Content-Type": "application/json; charset=UTF-8",
                    //     },
                    //     body: formData
                    // }).then(res => { return res.json() }).then(res => {
                    //     tempProductArr[i].files[0] = res.data.UploadReviewImage.media1;
                    //     tempProductArr[i].files[1] = res.data.UploadReviewImage.media2;
                    //     tempProductArr[i].files[2] = res.data.UploadReviewImage.media3;
                    //     tempProductArr[i].files[3] = res.data.UploadReviewImage.media4;
                    //     this.setState({ productsOrdered: tempProductArr });
                    //     if (i === productsOrdered.length - 1) {
                    //         this.submit(tempProductArr);
                    //     }
                    // })

                    var data = {
                        file: files
                    }
                    var formData = new FormData()
                    formData.append('operations', JSON.stringify({ "query": "mutation UploadReviewImage($file: [Upload!]!) {\nUploadReviewImage(file: $file){\ndata_status\ndata_message\nmedia1\nmedia2\nmedia3\nmedia4\n}\n}", "variables": { "file": null }, "operationName": "UploadReviewImage" }))

                    if (data.file.length == 1) {
                        formData.append('map', JSON.stringify({ "0": ["variables.file.0"] }))
                        formData.append(0, data.file[0])
                    }
                    else if (data.file.length == 2) {
                        formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], }))
                        formData.append(0, data.file[0])
                        formData.append(1, data.file[1])
                    }
                    else if (data.file.length == 3) {
                        formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], "2": ["variables.file.3"], }))
                        formData.append(0, data.file[0])
                        formData.append(1, data.file[1])
                        formData.append(2, data.file[2])
                    }
                    else {

                        formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], "2": ["variables.file.2"], "3": ["variables.file.3"] }))
                        formData.append(0, data.file.length ? data.file[0] : null)
                        formData.append(1, data.file.length > 1 ? data.file[1] : null)
                        formData.append(2, data.file.length > 2 ? data.file[2] : null)
                        formData.append(3, data.file.length > 3 ? data.file[3] : null)
                    }
                    await fetch(client.link.options.uri, {
                        method: 'POST',
                        header: {
                            'Access-Control-Allow-Origin': '*',
                            "Content-Type": "application/json; charset=UTF-8",
                        },
                        body: formData
                    }).then(async res => { return await res.json() }).then(res => {

                        if (!CommonConfig.isObjectEmpty(res)) {
                            tempProductArr[i].files = []
                            tempProductArr[i].files.push(
                                CommonConfig.isObjectEmpty(res) && CommonConfig.isEmpty(res.data.UploadReviewImage.media1) ? '' : res.data.UploadReviewImage.media1,
                                CommonConfig.isObjectEmpty(res) && CommonConfig.isEmpty(res.data.UploadReviewImage.media2) ? '' : res.data.UploadReviewImage.media2,
                                CommonConfig.isObjectEmpty(res) && CommonConfig.isEmpty(res.data.UploadReviewImage.media3) ? '' : res.data.UploadReviewImage.media3,
                                CommonConfig.isObjectEmpty(res) && CommonConfig.isEmpty(res.data.UploadReviewImage.media4) ? '' : res.data.UploadReviewImage.media4,
                            )
                            this.setState({ productsOrdered: tempProductArr });
                            if (tempProductArr[i].files.length && i === productsOrdered.length - 1) {
                                this.submit(tempProductArr);
                            }
                        }
                    })
                } else {
                    tempProductArr[i].files = []
                    tempProductArr[i].files.push('', '', '', '')
                    this.setState({ productsOrdered: tempProductArr });
                    if (tempProductArr[i].files.length && i === productsOrdered.length - 1) {
                        this.submit(tempProductArr);
                    }
                }
            }
        }
    }

    getSteps = () => {
        const { productsOrdered, StepCount, allow_media_upload, ProductReviewRatingErr, ProductReviewRatingErrText, ProductReviewTitleErr, ProductReviewTitleErrText, ProductReviewBodyErr, ProductReviewBodyErrText } = this.state;
        for (let i = 0; i < productsOrdered.length; i++) {
            if (StepCount === i) {
                return (
                    <>
                        <div className="w-1/2 mx-auto">
                            <img alt="PRODUCT IMAGE" src={productsOrdered[i].product_image1} className="object-cover overflow-hidden w-full h-80" onError={(ev) => {
                                ev.target.src = ImgNotFound
                            }} />
                        </div>
                        <br />
                        <div className="text-center">
                            <label className="text-black-500 text-4xl font-medium">{productsOrdered[i].title ? productsOrdered[i].title : "Product name not found"}</label>
                        </div>
                        <br />
                        <div className="px-5">
                            <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Rate this product</label>
                            <div className="mt-3">
                                {/* <ReactStars
                                    count={5}
                                    onChange={(e) => {
                                        var tempProductArr = productsOrdered;
                                        tempProductArr[i].product_reviews_rating = Number(e);
                                        this.setState({ productsOrdered: tempProductArr })
                                    }}
                                    size={30}
                                    value={productsOrdered.length && productsOrdered[i] && productsOrdered[i].product_reviews_rating ? productsOrdered[i].product_reviews_rating : 0}
                                    isHalf={false}
                                    emptyIcon={<FontAwesomeIcon icon={faStarAndCrescent} className="text-3xl ml-2 font-normal" style={{ color: this.state.ratingColor }} />}
                                    halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} className="text-3xl ml-2 font-normal" style={{ color: this.state.ratingColor }} />}
                                    fullIcon={<FontAwesomeIcon icon={faStar} className="text-3xl font-normal" style={{ color: this.state.ratingColor }} />}
                                    activeColor={this.state.ratingColor}
                                /> */}
                                <StarRatingComponent
                                    name={`rate-${i}`}
                                    // editing={true}
                                    emptyStarColor={"gray"}
                                    starColor={this.state.ratingColor}
                                    renderStarIcon={() => <FontAwesomeIcon icon={faStar} className="text-3xl font-normal mr-2"
                                    //style={{ color: this.state.ratingColor }} 
                                    />}
                                    starCount={5}
                                    value={productsOrdered[i].product_reviews_rating}
                                    onStarHover={(e, prevValue, name) => {
                                        var tempProductArr = productsOrdered;
                                        tempProductArr[i].product_reviews_rating = Number(e);
                                        this.setState({ productsOrdered: tempProductArr })
                                    }}
                                    onStarHoverOut={(e, prevValue, name) => {
                                        var tempProductArr = productsOrdered;
                                        if (!tempProductArr[i].product_reviews_rating_selected && tempProductArr[i].product_reviews_rating_selected != 1) {
                                            tempProductArr[i].product_reviews_rating = 0;
                                            this.setState({ productsOrdered: tempProductArr })
                                        } else {

                                        }
                                    }}
                                    onStarClick={(e, prevValue, name) => {
                                        var tempProductArr = productsOrdered;
                                        tempProductArr[i].product_reviews_rating = Number(e);
                                        tempProductArr[i].product_reviews_rating_selected = 1;
                                        this.setState({ productsOrdered: tempProductArr })
                                    }}
                                />
                                <span className='block mt-3'>
                                    {StepCount === this.state.ErrSteps ? ProductReviewRatingErr ? CommonConfig.showErrorMsg(ProductReviewRatingErrText) : null : null}
                                </span>
                            </div>
                        </div>
                        {/* <br /> */}
                        <div className="mt-4 px-5">
                            <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Review this product</label>
                            <div className="mt-3">
                                <label className="text-gray-500">Review title  </label>
                                <input type="text" id="create-account-pseudo" className="mb-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={productsOrdered[i].product_reviews_title} onChange={(e) => {
                                    var tempProductArr = productsOrdered;
                                    tempProductArr[i].product_reviews_title = e.target.value;
                                    this.setState({ productsOrdered: tempProductArr })
                                }} />
                                {StepCount === this.state.ErrSteps ? ProductReviewTitleErr ? CommonConfig.showErrorMsg(ProductReviewTitleErrText) : null : null}
                            </div>
                            <div className='mt-3'>
                                <label className="text-gray-500">Review description</label>
                                <textarea rows="4" maxLength={2000} type="text" id="create-account-pseudo" className="mb-2 resize-none rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={productsOrdered[i].product_reviews_body} onChange={(e) => {
                                    var tempProductArr = productsOrdered;
                                    tempProductArr[i].product_reviews_body = e.target.value;
                                    this.setState({ productsOrdered: tempProductArr })
                                }} />
                                {StepCount === this.state.ErrSteps ?
                                    ProductReviewBodyErr ? CommonConfig.showErrorMsg(ProductReviewBodyErrText) : null : null}
                            </div>
                        </div>
                        {allow_media_upload === "0" ? null :
                            <div className="dropzone-design-widget px-5 mt-4">

                                {/* <DropZone style={{ width: 363 }} onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => { 
                                     var tempProductArr = productsOrdered;
                                     if (tempProductArr[i].files.length < 5 && acceptedFiles.length < 5) {
                                         tempProductArr[i].files = [...tempProductArr[i].files, ...acceptedFiles];
                                         this.setState({ productsOrdered: tempProductArr })
                                     } else {
                                         tempProductArr[i].files = [];
                                         this.setState({ productsOrdered: tempProductArr })
                                     }
                                 }} allowMultiple={true}>
                                     {productsOrdered[i].files.length ?
                                         productsOrdered[i].files.map((file, index) => (
                                             <Stack alignment="center" key={index}>
                                                 <Thumbnail
                                                     size="small"
                                                     alt={file.name}
                                                     source={
                                                         this.state.validImageTypes.includes(file.type)
                                                             ? window.URL.createObjectURL(file)
                                                             : file
                                                     }
                                                 />
                                                 <div>
                                                     {file.name}
                                                 </div>
                                             </Stack>
                                         )) :
                                         <DropZone.FileUpload actionTitle="Upload Media" actionHint={"Upload Media Upto 4 files - JPG, JPEG or PNG (max size 3 MB), MP4 (max size 30 MB)"} />
                                     }
                                 </DropZone>*/}

                                <FilePond
                                    // ref={ref => (this[`${'pond'+i}`] = ref)}
                                    files={productsOrdered[i].files}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    credits={''}
                                    instantUpload={true}
                                    // server="./"
                                    name="files"
                                    labelIdle={this.state.videoUpload ? 'JPG, JPEG or PNG (max size 3 MB),  MP4 (max size 30 MB) <svg class="upload-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10a10 10 0 11-20 0 10 10 0 0120 0zM5.3 8.3l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L11 7.4V15a1 1 0 11-2 0V7.4L6.7 9.7a1 1 0 01-1.4-1.4z" fill="#5C5F62" class=""></path></svg> <span class="block filepond--label-action">Browse</span>' : 'JPG, JPEG or PNG (max size 3 MB) <svg class="upload-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10a10 10 0 11-20 0 10 10 0 0120 0zM5.3 8.3l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L11 7.4V15a1 1 0 11-2 0V7.4L6.7 9.7a1 1 0 01-1.4-1.4z" fill="#5C5F62" class=""></path></svg> <span class="block filepond--label-action">Browse</span>'}
                                    // oninit={() => this.handleDropZoneDrop()}
                                    // onupdatefiles={fileItems => {
                                    //     this.setState({
                                    //         files: fileItems.map(fileItem => fileItem.file)
                                    //     });
                                    // }}
                                    // onaddfile={(error,fileItems) => {
                                    //     this.handleDropZoneDrop(fileItems)
                                    // }}
                                    onupdatefiles={(fileItems) => {
                                        let tempProductArr = productsOrdered;
                                        if (tempProductArr[this.state.StepCount].files.length < 5 && fileItems.length < 5) {
                                            tempProductArr[this.state.StepCount].files = fileItems;
                                            this.setState({ productsOrdered: tempProductArr })
                                        }
                                        else {
                                            // tempProductArr[i].files = [];
                                            this.setState({ productsOrdered: tempProductArr })
                                        }
                                    }}
                                />

                            </div>}
                    </>
                )
            }
        }
    }

    render() {
        const { pageloading, IsSubmitEntered, IsValid, progressWidth, finalWidthPercentage, order_id, order_at, product_image1, title, allow_skip_review, accept_store_reviews, allow_media_upload, allow_video_upload, logo, productsOrdered, StepCount, ratingColor, widget_font_name, shopAverageRatingErr, shopAverageRatingErrText, shopReviewBodyErr, shopReviewBodyErrText, shopReviewTitleErr, shopReviewTitleErrText, shop_name } = this.state;

        if (IsSubmitEntered && !productsOrdered.filter(x => x.newfiles.length != x.files.length).length) {
            // console.log("test", StepCount, productsOrdered)
            this.submit(productsOrdered);
        }

        return (
            pageloading ?
                <Loading />
                :
                CommonConfig.isEmpty(this.state.ProductID) ?
                    <ErrorNotFound />
                    :
                    <div style={{ fontFamily: widget_font_name }} className="ml-2 mr-2">
                        {IsValid ?
                            <div className="w-100 my-5">
                                <div className="flex justify-center my-5 pt-7">
                                    {logo && !logo.includes("no-logo.png") ?
                                        <img alt="SHOP LOGO" src={logo} className="w-56" />
                                        :
                                        <span className='shop-title-name'>{shop_name}</span>
                                    }
                                </div>
                                <div className="max-w-3xl mx-auto shadow-md rounded-xl bg-white">
                                    <div className='overflow-auto'>
                                        <div className="relative">
                                            <div className="relative">
                                                <div className="overflow-hidden h-3 mb-4 text-xs flex rounded bg-indigo-200">
                                                    <div style={{ width: progressWidth + "%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"></div>
                                                </div>
                                            </div>
                                        </div>

                                        {StepCount === productsOrdered.length ?
                                            <>
                                                <div className="px-5 text-center">
                                                    <label className="text-black-600 tracking-wider font-bold text-4xl">How was your experience at our shop?</label>
                                                </div>
                                                <br />
                                                <br />
                                                <div className="px-5">
                                                    <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Rate this shop</label>
                                                    <div className="mt-3">
                                                        <StarRatingComponent
                                                            name={`rate-`}
                                                            emptyStarColor={"gray"}
                                                            starColor={this.state.ratingColor}
                                                            renderStarIcon={() => <FontAwesomeIcon icon={faStar} className="text-3xl font-normal mr-2"
                                                            />}
                                                            starCount={5}
                                                            value={this.state.shop_average_rating}
                                                            onStarHover={(e) => {
                                                                this.setState({ shop_average_rating: e })
                                                            }}
                                                            onStarHoverOut={(e) => {
                                                                this.setState({ shop_average_rating: e })
                                                            }}
                                                            onStarClick={(e) => {
                                                                this.setState({ shop_average_rating: e })
                                                            }}
                                                        />
                                                        {/* <ReactStars
                                                            count={5}
                                                            onChange={(e) => this.setState({ shop_average_rating: e })}
                                                            size={30}
                                                            isHalf={false}
                                                            emptyIcon={<FontAwesomeIcon icon={faStarAndCrescent} className="text-3xl ml-2 font-normal" style={{ color: this.state.ratingColor }} />}
                                                            halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} className="text-3xl ml-2 font-normal" style={{ color: this.state.ratingColor }} />}
                                                            fullIcon={<FontAwesomeIcon icon={faStar} className="text-3xl font-normal" style={{ color: this.state.ratingColor }} />}
                                                            activeColor={this.state.ratingColor}
                                                        /> */}
                                                        <span className='block mt-3'>
                                                            {shopAverageRatingErr ? CommonConfig.showErrorMsg(shopAverageRatingErrText) : null}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="px-5 mt-4">
                                                    <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Review this shop</label>
                                                    <div className="mt-3">
                                                        <label className="text-gray-500">Review title</label>
                                                        <input type="text" id="create-account-pseudo" className=" rounded-lg mb-2 border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.shop_review_title} onChange={(e) => this.setState({ shop_review_title: e.target.value })} />
                                                        {shopReviewTitleErr ? CommonConfig.showErrorMsg(shopReviewTitleErrText) : null}
                                                    </div>
                                                    <div className='mt-3'>
                                                        <label className="text-gray-500">Review description</label>
                                                        <textarea maxLength={2000} rows="4" type="text" id="create-account-pseudo" className="resize-none rounded-lg border-transparent flex-1 mb-2 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.shop_review_body} onChange={(e) => this.setState({ shop_review_body: e.target.value })} />
                                                        {shopReviewBodyErr ? CommonConfig.showErrorMsg(shopReviewBodyErrText) : null}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="px-5 text-center">
                                                    <label className="text-black-600 tracking-wider font-bold text-4xl">Rate your last purchase</label>
                                                    <div className="justify-center flex items-center mt-3">
                                                        <label className="text-gray-500 text-xl font-medium">Order no. </label>
                                                        <p className="text-black-500 text-md font-normal ml-3">
                                                            <strong>{order_id}</strong>
                                                        </p>
                                                        <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                                        </span>
                                                        <label className="text-gray-500 text-xl font-medium ml-3">Order date. </label>
                                                        <p className="text-black-500 text-md font-normal ml-2">
                                                            <strong>{order_at ? moment(order_at).format('MMM D,YYYY') : "No date available"}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <br />
                                                {this.getSteps()}
                                                {/* <div className="w-1/2 mx-auto">
                            <img alt="PRODUCT IMAGE" src={product_image1} className="object-cover overflow-hidden w-full h-80" />
                        </div>
                        <br />
                        <div className="text-center">
                            <label className="text-black-500 text-4xl font-medium">{title ? title : "Product name not found"}</label>
                        </div>
                        <br />
                        <div className="px-5">
                            <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Rate this product</label>
                            <div className="mt-3">
                                <ReactStars
                                    count={5}
                                    onChange={(e) => this.setState({ product_reviews_rating: e })}
                                    size={30}
                                    value={this.state.product_reviews_rating}
                                    isHalf={true}
                                    emptyIcon={<FontAwesomeIcon icon={faStarAndCrescent} className="text-3xl ml-2 font-normal" style={{ color:  this.state.ratingColor }} />}
                                    halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} className="text-3xl ml-2 font-normal" style={{ color:  this.state.ratingColor }} />}
                                    fullIcon={<FontAwesomeIcon icon={faStar} className="text-3xl font-normal" style={{ color:  this.state.ratingColor }} />}
                                    activeColor={this.state.ratingColor}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="px-5">
                            <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Review this product</label>
                            <div className="mt-3">
                                <label className="text-gray-500">Review title  </label>
                                <input type="text" id="create-account-pseudo" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.product_reviews_title} onChange={(e) => this.setState({ product_reviews_title: e.target.value })} />
                            </div>
                            <br />
                            <div>
                                <label className="text-gray-500">Review description</label>
                                <textarea rows="4" type="text" id="create-account-pseudo" className="resize-none rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.product_review_body} onChange={(e) => this.setState({ product_review_body: e.target.value })} />
                            </div>
                        </div>
                        <br />
                        <div className="dropzone-design-widget px-5">
                            {allow_media_upload === "no" ? null : <DropZone style={{ width: 363 }}>
                                <DropZone.FileUpload actionTitle="Upload Media" actionHint={"JPG, JPEG or PNG (max size 3 MB), MP4 (max size 30 MB)"} />
                            </DropZone>}
                        </div>
                        */}
                                            </>
                                        }
                                    </div>
                                    <div className='bg-white'>
                                        <hr className="mt-4" />
                                        <div className="flex justify-between p-4">
                                            <div>
                                                {StepCount !== 0 ?
                                                    <button className="bg-transparent hover:bg-blue-500 px-10 text-blue-700 font-semibold hover:text-white py-2 border border-blue-900 hover:border-transparent rounded-lg" onClick={(e) => this.setState({ StepCount: StepCount - 1, progressWidth: Number(progressWidth) - Number(finalWidthPercentage) })}>
                                                        Back
                                                    </button>
                                                    : null}
                                                {(accept_store_reviews === "no" && StepCount === productsOrdered.length - 1) || (StepCount === productsOrdered.length) || (allow_skip_review === "no") ?
                                                    null
                                                    :
                                                    <button className="bg-transparent hover:bg-blue-500 px-10 text-blue-700 font-semibold hover:text-white py-2 rounded-lg" onClick={(e) => this.skipStep("EveryProduct")}>
                                                        Skip
                                                    </button>}
                                                {/* {StepCount === productsOrdered.length - 1 || StepCount === productsOrdered.length ? StepCount === productsOrdered.length ?
                                                    null
                                                    :
                                                    <button className="bg-transparent hover:bg-blue-500 px-10 text-blue-700 font-semibold hover:text-white py-2 rounded-lg" onClick={(e) => this.skipStep("LastProduct")}>
                                                        Skip
                                                    </button>
                                                    :
                                                    <button className="bg-transparent hover:bg-blue-500 px-10 text-blue-700 font-semibold hover:text-white py-2 rounded-lg" onClick={(e) => this.skipStep("EveryProduct")}>
                                                        Skip
                                                    </button>
                                                } */}
                                            </div>
                                            {accept_store_reviews === "no" && StepCount === productsOrdered.length - 1 ?
                                                <button type="button" className="py-3 px-24 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-2xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " onClick={async (e) => {
                                                    await this.shopVerifiedReview(e);
                                                }}>
                                                    Submit
                                                </button>
                                                :
                                                <button type="button" className="py-3 px-24 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-2xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " onClick={(e) => {
                                                    if (StepCount === productsOrdered.length) {
                                                        this.shopVerifiedReview(e);
                                                    } else {
                                                        this.nextProductReview(e);
                                                        // this.setState({ StepCount: StepCount + 1, progressWidth: Number(progressWidth) + Number(finalWidthPercentage) })
                                                    }
                                                }}>
                                                    {StepCount === productsOrdered.length ? "Submit" : "Next"}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="flex items-center justify-center pb-5">
                                    <a href="https://apps.shopify.com/shynr" target="_blank" className="mr-2 font-medium">
                                        Powered by Shynr
                                    </a>
                                </div>
                            </div>
                            :
                            <ErrorMessage />
                        }
                    </div>
        )
    }
}