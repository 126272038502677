import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faStar, faThumbsUp, faThumbsDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import * as Constants from '../../utils/graphql_queries.js';
import { client } from '../../utils/config';
import ImageGallery from 'react-image-gallery';
import moment from "moment";
import CommonConfig from '../../utils//constant';
import verifyBadgeGreen from "../../assets/img/verify-badge.svg";
import ErrorNotFound from '../404Error/404Error';
import Loading from '../Loading/Loading';
import WebFont from 'webfontloader';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ImgNotFound from "../../assets/img/company-Image.png";
import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player';

const initialState = {

    product_title_product: "",
    rating_product: "",
    is_verified: "",
    review_title_product: "",
    review_body_product: "",
    reviewer_name_product: "",
    reviewer_email_product: "",
    media_1_product: "",
    media_2_product: "",
    media_3_product: "",
    media_4_product: "",
    reviewer_country_product: "",
    upvote_count_product: "",
    downvote_count_product: "",
    status_product: "",
    created_at_product: "",
    public_reply_product: "",
    public_replied_at_product: "",
    title_product: "",
    handle_product: "",
    IsReadMoreReviewSelected: false,
    IsReadMoreReplySelected: false,
    initials: 'JD',
    review_id: '',
    images: [],
    ProductID: '',
    ReviewerID: '',
    loading: true,
    timeZone: '',
    hide_product_link: "",
    isMediaExist:false
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default class ReviewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        var url = new URL(window.location.href);
        var productid = url.searchParams.get("id");
        var reviewer_id = url.searchParams.get("reviewer_id");
        var isMediaExist = url.searchParams.get("media_exist");
        this.setState({isMediaExist:isMediaExist}) 
        var widgetSettingsShopURL = ''
        if (url.searchParams.get("shop_url")) {
            widgetSettingsShopURL = url.searchParams.get("shop_url");
        }
        if (!CommonConfig.isEmpty(productid) && !CommonConfig.isEmpty(widgetSettingsShopURL)) {
            this.setState({ ProductID: productid, ReviewerID: reviewer_id })
            this.getWedigetsAdminSettings(widgetSettingsShopURL);
        } else {
            this.setState({ loading: false })
        }
     
    }

    getProductReviews = (id) => {
        var data = {
            id: id
        }
        client
            .query({
                query: Constants.VIEW_PRODUCT_REVIEW,
                variables: data,
            })
            .then(async result => {

                var images = [];
                // images.push({
                //     thumbnail: await CommonConfig.generateVideoThumbnail('https://apps3.findshop.co/media/1.mp4'),
                //     original: await CommonConfig.generateVideoThumbnail('https://apps3.findshop.co/media/1.mp4'),
                //     embedUrl: 'https://www.youtube.com/embed/4pSzhZ76GdM?autoplay=1&showinfo=0',
                //     renderItem: this.renderVideo.bind(this)
                // })
                if (result.data.ProductReview.media1) {
                    let checkExtension = CommonConfig.findExtesionFromURL(result.data.ProductReview.media1)

                    if (checkExtension && (checkExtension == 'mp4' || checkExtension == '3gp')) {
                        images.push({
                            thumbnail: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media1),
                            original: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media1),
                            embedUrl: result.data.ProductReview.media1,
                            renderItem: this.renderVideo.bind(this)
                        })
                    }
                    else {
                        images.push({
                            original: result.data.ProductReview.media1,
                            thumbnail: result.data.ProductReview.media1,
                        })
                    }
                }
                if (result.data.ProductReview.media2) {
                    let checkExtension = CommonConfig.findExtesionFromURL(result.data.ProductReview.media2)

                    if (checkExtension && (checkExtension == 'mp4' || checkExtension == '3gp')) {
                        images.push({
                            thumbnail: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media2),
                            original: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media2),
                            embedUrl: result.data.ProductReview.media2,
                            renderItem: this.renderVideo.bind(this)
                        })
                    }
                    else {
                        images.push({
                            original: result.data.ProductReview.media2,
                            thumbnail: result.data.ProductReview.media2,
                        })
                    }
                }
                if (result.data.ProductReview.media3) {
                    let checkExtension = CommonConfig.findExtesionFromURL(result.data.ProductReview.media3)

                    if (checkExtension && (checkExtension == 'mp4' || checkExtension == '3gp')) {
                        images.push({
                            thumbnail: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media3),
                            original: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media3),
                            embedUrl: result.data.ProductReview.media3,
                            renderItem: this.renderVideo.bind(this)
                        })
                    }
                    else {
                        images.push({
                            original: result.data.ProductReview.media3,
                            thumbnail: result.data.ProductReview.media3,
                        })
                    }
                }
                if (result.data.ProductReview.media4) {
                    let checkExtension = CommonConfig.findExtesionFromURL(result.data.ProductReview.media4)

                    if (checkExtension && (checkExtension == 'mp4' || checkExtension == '3gp')) {
                        images.push({
                            thumbnail: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media4),
                            original: await CommonConfig.generateVideoThumbnail(result.data.ProductReview.media4),
                            embedUrl: result.data.ProductReview.media4,
                            renderItem: this.renderVideo.bind(this)
                        })
                    }
                    else {
                        images.push({
                            original: result.data.ProductReview.media4,
                            thumbnail: result.data.ProductReview.media4,
                        })
                    }
                }
                var firstName = result.data.ProductReview.reviewer_name.split(' ').slice(0, 1).join(' ');
                var lastName = result.data.ProductReview.reviewer_name.split(' ').slice(-1).join(' ');
                var initials = firstName.charAt(0) + lastName.charAt(0);
                this.setState({
                    product: result.data.ProductReview.product,
                    review_id: result.data.ProductReview.id,
                    product_title_product: result.data.ProductReview.product_title,
                    rating_product: result.data.ProductReview.rating,
                    is_verified: result.data.ProductReview.is_verified,
                    review_title_product: result.data.ProductReview.review_title,
                    review_body_product: result.data.ProductReview.review_body,
                    reviewer_name_product: result.data.ProductReview.reviewer_name,
                    reviewer_email_product: result.data.ProductReview.reviewer_email,
                    media_1_product: result.data.ProductReview.media1,
                    media_2_product: result.data.ProductReview.media2,
                    media_3_product: result.data.ProductReview.media3,
                    media_4_product: result.data.ProductReview.media4,
                    reviewer_country_product: result.data.ProductReview.reviewer_country,
                    upvote_count_product: result.data.ProductReview.upvote_count,
                    downvote_count_product: result.data.ProductReview.downvote_count,
                    status_product: result.data.ProductReview.status,
                    created_at_product: result.data.ProductReview.created_at,
                    public_reply_product: result.data.ProductReview.public_reply,
                    public_replied_at_product: result.data.ProductReview.public_replied_at,
                    title_product: result.data.ProductReview.product.title,
                    handle_product: result.data.ProductReview.product.handle,
                    initials: initials,
                    images: images,
                })

                //setTimeout(()=>{ this.setState({loading : false}) }, 2000);
                this.setState({ loading: false })
            }).catch(() => {
            });
    }

    getWedigetsAdminSettings = (widgetSettingsShopURL) => {
        fetch(widgetSettingsShopURL).then(res => { return res.json() }).then(result => {

            this.setState({
                background_color: result[0].background_color,
                card_widget: result[0].card_widget,
                customer_photo: result[0].customer_photo,
                display_customer_image_box: result[0].display_customer_image_box,
                floating_tab_background_color: result[0].floating_tab_background_color,
                floating_tab_font_color: result[0].floating_tab_font_color,
                floating_widget: result[0].floating_widget,
                floating_widget_position: result[0].floating_widget_position,
                frequent_mentions: result[0].frequent_mentions,
                hide_findshop_branding: result[0].hide_findshop_branding,
                hide_product_link: result[0].hide_product_link,
                hide_star_no_reviews: result[0].hide_star_no_reviews,
                max_review_display: result[0].max_review_display,
                page_widget: result[0].page_widget,
                primary_color: result[0].primary_color,
                product_widget: result[0].product_widget,
                progressbar_theme: result[0].progressbar_theme,
                rating_star_color: result[0].rating_star_color,
                review_publish_date: result[0].review_publish_date,
                reviewer_country_flag: result[0].reviewer_country_flag,
                reviewer_name: result[0].reviewer_name,
                reviewer_name_style: result[0].reviewer_name_style,
                share_button: result[0].share_button,
                shop_id: result[0].shop_id,
                social_proof_widget: result[0].social_proof_widget,
                social_proof_widget_delay: result[0].social_proof_widget_delay,
                social_proof_widget_position: result[0].social_proof_widget_position,
                social_proof_widget_style: result[0].social_proof_widget_style,
                title_widget: result[0].title_widget,
                title_widget_style: result[0].title_widget_style,
                widget_font: result[0].widget_font,
                widget_font_name: result[0].widget_font_name,
                widget_language: result[0].widget_language,
                widget_summary_bar: result[0].widget_summary_bar,
                write_review_button: result[0].write_review_button,
                timeZone: result[0].time_zone,
            })
            this.getProductReviews(this.state.ReviewerID, "oldest", 25, result[0].shop_id);
            WebFont.load({
                google: {
                    families: [result[0].widget_font]
                }
            })
        })
    }

    checkLikeUnlike(key, id) {
        var LikeUnlikes = JSON.parse(localStorage.getItem(key));

        if (LikeUnlikes != null && LikeUnlikes.includes(id)) {
            return false
        }

        if (LikeUnlikes != null) {
            LikeUnlikes.push(id);
        }
        else {
            LikeUnlikes = [id];
        }
        localStorage.setItem(key, JSON.stringify(LikeUnlikes));
        return true
    }

    upvoteProduct = async (id) => {
        var data = {
            "review_id": id
        }

        if (this.checkLikeUnlike("upvoteProduct", id)) {
            await client
                .mutate({
                    mutation: Constants.PRODUCT_UPVOTE,
                    variables: data
                })
                .then(async () => {
                    this.setState({
                        upvote_count_product: parseInt(this.state.upvote_count_product, 10) + 1
                    })
                    // console.log("result", result.data.ProductReviewUpvote.data_message)
                }).catch(() => {
                    // console.log("result", error)
                });
        }
    }

    downvoteProduct = async (id) => {
        var data = {
            "review_id": id
        }
        if (this.checkLikeUnlike("downvoteProduct", id)) {
            await client
                .mutate({
                    mutation: Constants.PRODUCT_DOWNVOTE,
                    variables: data

                })
                .then(async () => {
                    this.setState({
                        downvote_count_product: parseInt(this.state.downvote_count_product, 10) + 1
                    })
                    // console.log("result", result.data.ProductReviewDownvote.data_message)
                }).catch(() => {
                    // console.log("result", error)
                });
        }
    }

    renderVideo(item) {
        return (
            <div>
                {
                    item.embedUrl ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                            // onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                            >
                            </a>
                            <ReactPlayer className="m-auto video-container" style={{ padding: '12%' }} width="auto" height="100%" url={item.embedUrl} showPlayButton={true} controls={true} />
                            {/* <iframe
                                width='560'
                                height='315'
                                src={item.embedUrl}
                                frameBorder='0'
                                allowFullScreen
                            >
                            </iframe> */}
                        </div>
                        :
                        <a /*onClick={this._toggleShowVideo.bind(this, item.embedUrl)}*/>
                            <div className='play-button'></div>
                            <img className='image-gallery-image' src={item.original} />
                            {
                                item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{ right: '0', left: 'initial' }}
                                >
                                    {item.description}
                                </span>
                            }
                        </a>
                }
            </div>
        );
    }

    render() {
        const { timeZone, customer_photo, loading, images, review_id, initials, primary_color, background_color, review_publish_date, share_button, hide_star_no_reviews, rating_product, review_title_product, review_body_product, reviewer_name_product, upvote_count_product, downvote_count_product, created_at_product, public_reply_product, public_replied_at_product, reviewer_country_product, is_verified, rating_star_color, widget_font_name, handle_product, hide_product_link, isMediaExist } = this.state;
        return (
            <>
                { loading ?
                    <Loading layoutType={isMediaExist == "true" ? 'Review_Detail' : 'Review_Detail_No_Media'} />
                    :
                    CommonConfig.isEmpty(this.state.ProductID) && CommonConfig.isEmpty(this.state.ReviewerID) ?
                        <ErrorNotFound />
                        :
                        <div className={"grid grid-cols-1 " + (customer_photo === "1" ? images && images.length ? "md:grid-cols-2" : "md:grid-cols-1" : "md:grid-cols-1")} style={{ backgroundColor: background_color, fontFamily: widget_font_name, height: '100vh' }}>
                            {customer_photo === "1" ?
                                images && images.length ?
                                    (<div className="bg-black">
                                        <ImageGallery items={images} onErrorImageURL={ImgNotFound} showPlayButton={true}
                                        // additionalClass="app-image-gallery"
                                        />
                                    </div>) : null
                                : null
                            }

                            <div className="p-4 md:border-l lg:border-l h-100 overflow-auto">
                                <div className="flex items-center justify-between mb-3">
                                    {hide_star_no_reviews === "0" ? <div className="flex items-center">
                                        <FontAwesomeIcon icon={faStar} style={{ color: rating_star_color, fontSize: '18px' }} />
                                        <div className="ml-2 ">
                                            <span className="text-3xl font-bold" style={{ color: primary_color }}>{rating_product}</span><span className="text-3xl font-normal" style={{ color: primary_color }}>/5</span>
                                        </div>
                                    </div> : null}
                                    <div className="pt-1.5">
                                        <button style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => window.parent.postMessage('reviewDetailsClose', '*')}>
                                            <FontAwesomeIcon icon={faTimes} style={{ color: '#5C5F62', fontSize: '20px' }} />
                                        </button>
                                    </div>
                                </div>
                                <div className="relative">
                                    <label className="text-black-400 text-2xl font-bold review-title" style={{ color: primary_color }}>{review_title_product}.</label>
                                    <p className="text-gray-500 text-2xl font-normal mt-2" style={{ color: primary_color }}>{ReactHtmlParser(review_body_product)}</p>
                                </div>
                                <br />
                                <div className="flex items-center">
                                    {public_reply_product === null ? null : <label className="text-black-400 text-2xl font-bold">Reply from shop</label>}
                                    {public_replied_at_product === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                    </span>}
                                    {review_publish_date === "1" ?
                                        <p className="text-gray-400 text-md font-normal ml-3">
                                            {public_replied_at_product ? moment(public_replied_at_product).fromNow() : ""}
                                            {/* {public_replied_at_product ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(public_replied_at_product), timeZone)).fromNow() : '-'} */}
                                        </p>
                                        : null
                                    }
                                    {/* {this.state.reviewer_country_flag == '1' ?
                                        <div>&nbsp;&nbsp;<span className={`mt-2 ml-2 flag-icon flag-icon-${reviewer_country_product ? reviewer_country_product.toLowerCase() : null}`}></span></div> : null} */}
                                </div>
                                {public_reply_product === null ? null :
                                    <div className="mt-2">
                                        <p className="text-gray-500 text-2xl font-normal mt-2" style={{ color: primary_color }}>{public_reply_product}</p>
                                    </div>
                                }
                                <br />
                                <div className="flex items-center justify-between" >
                                    <div className=" flex items-center">
                                        <div>
                                            <span class="text-gray-700 font-medium text-16 flex items-center justify-center bg-gray-300 w-14 h-14 mr-2 rounded-full">{initials}</span>
                                        </div>
                                        <label className="text-xl font-semibold ml-3 customer-name text-gray-400">{reviewer_name_product}</label>
                                        {created_at_product === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                        </span>}
                                        {review_publish_date === "1" ?
                                            <p className="text-gray-400 text-xl font-normal ml-3">
                                                {created_at_product ? moment(created_at_product).fromNow() : ""}
                                                {/* {created_at_product ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(created_at_product), timeZone)).fromNow() : '-'} */}

                                            </p>
                                            :
                                            null
                                        }
                                        {this.state.reviewer_country_flag == '1' ?
                                            <div>&nbsp;&nbsp;<span className={`mt-2 ml-2 flag-icon flag-icon-${reviewer_country_product ? reviewer_country_product.toLowerCase() : null}`}></span></div> : null}

                                    </div>
                                    <div className='flex align-items-center'>
                                        {is_verified === "1" ?
                                            <div className='mr-2'>
                                                <span className="rounded-full uppercase text-white text-base font-medium px-3 py-2 leading-none flex items-center">
                                                    <img src={verifyBadgeGreen} alt="" className="text-light-green w-8 h-8 justify-between" />
                                                    &nbsp; <span className="text-light-green text-nowrap uppercase text-12">Verified purchase</span>
                                                </span>
                                            </div>
                                            : null}
                                        {handle_product && hide_product_link == '0' ?
                                            <div className='text-right'>
                                                <button className='font-weight-500 mr-4 text-14 text-blue-900 border-bottom' onClick={() => window.parent.postMessage(`viewProduct?url=${handle_product}`, '*')}>View Product</button>
                                            </div>
                                            :
                                            null}
                                            
                                    </div>

                                </div>
                                <hr className="mt-4 mb-4" />
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="cursorp ml-4 items-center flex" onClick={() => this.upvoteProduct(review_id)}>
                                            <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                            <label className="cursorp text-gray-600 text-xl font-medium">helpful ({CommonConfig.isConvert(upvote_count_product ? upvote_count_product : 0)})</label>
                                        </div>
                                        <div className="cursorp ml-4 items-center flex" onClick={() => this.downvoteProduct(review_id)}>
                                            <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                            <label className="cursorp text-gray-600 text-xl font-medium">({CommonConfig.isConvert(downvote_count_product ? downvote_count_product : 0)})</label>
                                        </div>
                                    </div>
                                    {share_button === "1" ? <div>
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 text-gray-700">
                                                    <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href={"https://www.facebook.com/sharer/sharer.php?u=https://s.shynr.co/share/" + review_id}
                                                                    target="_blank"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-xl font-medium'
                                                                    )}
                                                                >
                                                                    <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href={"https://twitter.com/intent/tweet?url=https://s.shynr.co/share/" + review_id}
                                                                    target="_blank"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-xl font-medium'
                                                                    )}
                                                                >
                                                                    <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div> : null}
                                </div>


                            </div>
                        </div>
                }
            </>
        )
    }
}
