import React, { Component, Fragment } from 'react'
import ImgNotFound from "../../assets/img/company-Image.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faStar, faThumbsUp, faThumbsDown, faStarHalfAlt, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarO } from '@fortawesome/free-regular-svg-icons';
import verifyBadge from "../../assets/img/verify-badge-white.svg";
import verifyBadgeGreen from "../../assets/img/verify-badge.svg";
import shynrIcon from '../../assets/img/shynr.svg';
import * as Constants from '../../utils/graphql_queries.js';
import { client } from '../../utils/config';
import moment from "moment";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import CommonConfig from '../../utils//constant';
import ErrorNotFound from '../404Error/404Error';
import Loading from '../Loading/Loading';
import WebFont from 'webfontloader';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const initialState = {
    tags: [],
    widget_design: "list",
    productListReviews: [],
    overall_rating: "",
    media1: "",
    media2: "",
    media3: "",
    media4: "",
    progressbar_theme: "",
    primary_color: "",
    background_color: "",
    write_review_button: "",
    reviewer_name: "",
    review_publish_date: "",
    customer_photo: "",
    share_button: "",
    hide_star_no_reviews: "",
    widget_summary_bar: "",
    media_count: 0,
    active_product_reviews_count: "",
    star_5_count: "",
    star_4_count: "",
    star_3_count: "",
    star_2_count: "",
    star_1_count: "",
    loadMoreCount: 0,
    firstName: "",
    lastName: "",
    loading: true,
    LoadMoreTotal: 1,
    loadMorepage: 1,
    sortBy: '',
    sortByValue: '',
    CustomerImages: [],
    ProductID: '',
    pageloading: true,
    isNotFound: false,
    timeZone: '',
    btn_color: '#2563EB',
    isHoverActive: false,
}

export default class ProductReview extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount = async () => {
        var url = new URL(window.location.href);
        var productid = url.searchParams.get("id");
        var widgetSettingsShopURL = ''
        if (url.searchParams.get("shop_url")) {
            widgetSettingsShopURL = url.searchParams.get("shop_url");
        }
        if (!CommonConfig.isEmpty(productid) && !CommonConfig.isEmpty(widgetSettingsShopURL)) {
            this.setState({ ProductID: productid })
            await this.getWedigetsAdminSettings(productid, widgetSettingsShopURL);
            await this.getCustomerImageCount(productid);
            await this.getCustomerImages(productid, 10);
            await this.getFrequentMentionTags(productid);
            await this.getSummaryBar(productid);
            setTimeout(() => {
                this.setState({ pageloading: false }); let root = document.getElementById('root');
                let message = { height: root.scrollHeight, width: root.scrollWidth };
                window.top.postMessage(message, "*");
            }, 2000);
        } else {
            this.setState({ pageloading: false })
        }

        let root = document.getElementById('root');
        let message = { height: root.scrollHeight, width: root.scrollWidth };
        window.top.postMessage(message, "*");
    }

    getProductReviews = async (product_id, sort, first, shop_id) => {
        var data = {
            product_id: product_id,
            sort: sort,
            first: first,
            shop_id: this.state.shop_id
            // shop_id: shop_id.toString(),
        }
        await client
            .query({
                query: Constants.PRODUCT_REVIEW,
                variables: data,
            })
            .then(async result => {
                let reviewListT = result.data.WidgetListProductReview.data;
                let reviewList = [...reviewListT]
                for (let j = 0; j < reviewList.length; j++) {
                    let review = reviewList[j]
                    for (let i = 0; i < 4; i++) {
                        if (!CommonConfig.isEmpty(review[`media${i + 1}`])) {
                            if (!CommonConfig.isEmpty(review[`media${i + 1}`]) && CommonConfig.findExtesionFromURL(review[`media${i + 1}`]) && (CommonConfig.findExtesionFromURL(review[`media${i + 1}`]) == 'mp4' || CommonConfig.findExtesionFromURL(review[`media${i + 1}`]) == '3gp')) {
                                review = { ...review, [`media${i + 1}new`]: await CommonConfig.generateVideoThumbnail(review[`media${i + 1}`]) };
                            }
                            else {
                                review = { ...review, [`media${i + 1}new`]: review[`media${i + 1}`] };
                            }
                        }
                        else {
                            if (this.state.widget_design != "list") {
                                review = { ...review, [`media${i + 1}new`]: ImgNotFound };
                            }
                        }
                    }
                    reviewList[j] = review
                }
                // if (result.data.WidgetListProductReview.data.length > 0) {
                this.setState({
                    loading: false, productListReviews: reviewList.map((item) => ({
                        ...item,
                        IsReadMoreSelected: false
                    })),
                    // LoadMoreTotal: Math.ceil(result.data.WidgetListProductReview.paginatorInfo.total / first)
                })

                if (this.state.LoadMoreTotal === 1) {
                    this.setState({
                        LoadMoreTotal: Math.ceil(result.data.WidgetListProductReview.paginatorInfo.total / first)
                    })
                }

                let root = document.getElementById('root');
                let message = { height: root.scrollHeight, width: root.scrollWidth };
                window.top.postMessage(message, "*");
                // }
            }).catch((error) => {
            });

    }

    getWedigetsAdminSettings = async (productid, widgetSettingsShopURL) => {
        await fetch(widgetSettingsShopURL).then(res => {
            if (res.status == 404) {
                return ({ status: 404 })
            } else { return res.json() }
        }).then(result => {
            if (!CommonConfig.isObjectEmpty(result) && result.status == 404) {
                this.setState({ pageloading: false, isNotFound: true })
            }
            else if (result.length > 0) {

                this.setState({
                    btn_color: result[0].btn_color ? result[0].btn_color : this.state.btn_color,
                    background_color: result[0].background_color,
                    card_widget: result[0].card_widget,
                    widget_design: result[0].product_widget_type,
                    customer_photo: result[0].customer_photo,
                    display_customer_image_box: result[0].display_customer_image_box,
                    floating_tab_background_color: result[0].floating_tab_background_color,
                    floating_tab_font_color: result[0].floating_tab_font_color,
                    floating_widget: result[0].floating_widget,
                    floating_widget_position: result[0].floating_widget_position,
                    frequent_mentions: result[0].frequent_mentions,
                    hide_findshop_branding: result[0].hide_findshop_branding,
                    hide_product_link: result[0].hide_product_link,
                    hide_star_no_reviews: result[0].hide_star_no_reviews,
                    max_review_display: result[0].max_review_display,
                    loadMoreCount: result[0].max_review_display,
                    page_widget: result[0].page_widget,
                    primary_color: result[0].primary_color,
                    product_widget: result[0].product_widget,
                    progressbar_theme: result[0].progressbar_theme,
                    rating_star_color: result[0].rating_star_color,
                    review_publish_date: result[0].review_publish_date,
                    reviewer_country_flag: result[0].reviewer_country_flag,
                    reviewer_name: result[0].reviewer_name,
                    reviewer_name_style: result[0].reviewer_name_style,
                    share_button: result[0].share_button,
                    shop_id: result[0].shop_id,
                    social_proof_widget: result[0].social_proof_widget,
                    social_proof_widget_delay: result[0].social_proof_widget_delay,
                    social_proof_widget_position: result[0].social_proof_widget_position,
                    social_proof_widget_style: result[0].social_proof_widget_style,
                    title_widget: result[0].title_widget,
                    title_widget_style: result[0].title_widget_style,
                    widget_font: result[0].widget_font,
                    widget_font_name: result[0].widget_font_name,
                    widget_language: result[0].widget_language,
                    widget_summary_bar: result[0].widget_summary_bar,
                    write_review_button: result[0].write_review_button,
                    sortBy: "Newest",
                    sortByValue: "newest",
                    timeZone: result[0].time_zone,
                })

                this.getProductReviews(productid, "newest", result[0].max_review_display ? Number(result[0].max_review_display) : 25, 1);
                WebFont.load({
                    google: {
                        families: [result[0].widget_font]
                    }
                })
            }
        })
    }

    getCustomerImageCount = async (product_id) => {

        var data = {
            product_id: product_id.toString(),
            shop_id: this.state.shop_id
        }
        await client
            .query({
                query: Constants.CUSTOMER_IMAGE_COUNT,
                variables: data,
            })
            .then(result => {
                if (result.data.CustomerImagesCount.length > 0) {
                    this.setState({
                        media_count: result.data.CustomerImagesCount[0].media_count,
                    })
                }
            }).catch((error) => {
            });
    }

    getCustomerImages = async (product_id, first) => {

        var data = {
            product_id: product_id,
            first: first,
            shop_id: this.state.shop_id
        }
        await client
            .query({
                query: Constants.CUSTOMER_IMAGES,
                variables: data,
            })
            .then(result => {
                if (result.data.CustomerImages.data.length > 0) {
                    var Images = [];
                    result.data.CustomerImages.data.map(x => {
                        if (!CommonConfig.isEmpty(x.media1)) {
                            Images.push(x.media1)
                        }
                        if (!CommonConfig.isEmpty(x.media2)) {
                            Images.push(x.media2)
                        }
                        if (!CommonConfig.isEmpty(x.media3)) {
                            Images.push(x.media3)
                        }
                        if (!CommonConfig.isEmpty(x.media4)) {
                            Images.push(x.media4)
                        }
                    })

                    this.setState({
                        CustomerImages: Images,
                        // media1: result.data.CustomerImages.data[0].media1,
                        // media2: result.data.CustomerImages.data[0].media2,
                        // media3: result.data.CustomerImages.data[0].media3,
                        // media4: result.data.CustomerImages.data[0].media4,

                    })
                }
            }).catch((error) => {
            });
    }

    getFrequentMentionTags = async (product_id) => {

        var data = {
            product_id: product_id,
            shop_id: this.state.shop_id
        }
        await client
            .query({
                query: Constants.FREQUENT_MENTION_TAGS,
                variables: data,
            })
            .then(result => {
                if (result.data.WidgetFrequentMetionsTags.length > 0) {

                    this.setState({

                        tags: result.data.WidgetFrequentMetionsTags,
                    })

                }
            }).catch((error) => {
            });
    }

    getSummaryBar = async (product_id) => {

        var data = {
            product_id: product_id,
            shop_id: this.state.shop_id
        }
        await client
            .query({
                query: Constants.SUMMARY_BAR,
                variables: data,
            })
            .then(result => {
                if (result.data.WidgetBars.length > 0) {
                    this.setState({
                        overall_rating: result.data.WidgetBars[0].overall_rating,
                        active_product_reviews_count: result.data.WidgetBars[0].active_product_reviews_count,
                        star_5_count: result.data.WidgetBars[0].star_5_count,
                        star_4_count: result.data.WidgetBars[0].star_4_count,
                        star_3_count: result.data.WidgetBars[0].star_3_count,
                        star_2_count: result.data.WidgetBars[0].star_2_count,
                        star_1_count: result.data.WidgetBars[0].star_1_count,

                    })
                }
            }).catch((error) => {
            });
    }

    checkLikeUnlike(key, id) {
        var LikeUnlikes = JSON.parse(localStorage.getItem(key));

        if (LikeUnlikes != null && LikeUnlikes.includes(id)) {
            return false
        }

        if (LikeUnlikes != null) {
            LikeUnlikes.push(id);
        }
        else {
            LikeUnlikes = [id];
        }
        localStorage.setItem(key, JSON.stringify(LikeUnlikes));
        return true
    }

    upvoteProduct = async (item, idx) => {
        // debugger
        var data = {
            "review_id": item.id
        }
        if (this.checkLikeUnlike("upvoteProduct", item.id)) {
            var tempProductReviewList = this.state.productListReviews;
            tempProductReviewList[idx]['upvote_count'] = parseInt(tempProductReviewList[idx]['upvote_count'], 10) + 1;
            this.setState({ productListReviews: tempProductReviewList })
            await client
                .mutate({
                    mutation: Constants.PRODUCT_UPVOTE,
                    variables: data

                })
                .then(async result => {
                    // console.log("result", result.data.ProductReviewUpvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }

    downvoteProduct = async (item, idx) => {
        var data = {
            "review_id": item.id
        }
        if (this.checkLikeUnlike("downvoteProduct", item.id)) {
            var tempProductReviewList = this.state.productListReviews;
            tempProductReviewList[idx]['downvote_count'] = parseInt(tempProductReviewList[idx]['downvote_count'], 10) + 1;
            this.setState({ productListReviews: tempProductReviewList })
            await client
                .mutate({
                    mutation: Constants.PRODUCT_DOWNVOTE,
                    variables: data

                })
                .then(async result => {
                    // console.log("result", result.data.ProductReviewDownvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }

    render() {

        var fields = [];
        if (this.state.overall_rating) {
            for (let i = 1; i <= 5; i++) {
                if (i <= Math.round(Number(this.state.overall_rating))) {
                    fields.push(<FontAwesomeIcon icon={faStar} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "20px" }} />);
                }
                else {
                    fields.push(<FontAwesomeIcon icon={faStarO} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "20px" }} />);
                }
            }
        }
        var product_name = [];
        // if (this.state.reviewer_name_product) {
        //     var firstName =this.state.reviewer_name_product.split(' ').slice(0,1).join(' ');
        //     var lastName = this.state.reviewer_name_product.split(' ').slice(-1).join(' ');
        //     var initials = firstName. charAt(0) + lastName.charAt(0);


        //     return initials.toUpperCase();



        // }


        const { timeZone, isNotFound, pageloading, CustomerImages, sortBy, overall_rating, media1, media2, media3, media4, progressbar_theme, primary_color, background_color, write_review_button, reviewer_name, review_publish_date, customer_photo, share_button, hide_star_no_reviews, widget_summary_bar, media_count, active_product_reviews_count, star_5_count, star_4_count, star_3_count, star_2_count, star_1_count, tags, widget_design, productListReviews, widget_font_name, hide_findshop_branding, isHoverActive, isMediaExist } = this.state;

        console.log("CustomerImages", CustomerImages)

        return (
            pageloading ?
                <Loading layoutType={widget_summary_bar === "left" ? 'Product_Review' : 'Product_Review_Top'} />
                :
                <div>
                    {CommonConfig.isEmpty(this.state.ProductID) || isNotFound ?
                        <ErrorNotFound />
                        :
                        // {/* left view */}
                        <div
                            className={widget_summary_bar === "left" ? "grid grid-cols-1 md:grid-cols-12 gap-6 ml-2 mr-2" : "ml-2 mr-2"}
                            style={{ fontFamily: widget_font_name }}
                        >
                            <div class="md:col-start-1 md:col-span-12 pt-4 pb-4">
                                <div class="flex items-center justify-between">
                                    <h1 class="text-3xl mb-2 text-gray-400">Customer reviews</h1>
                                    {hide_findshop_branding == '1' ? null :
                                        <div class="d-flex align-items-center">
                                            <a href="https://apps.shopify.com/shynr" target="_blank" class="mr-2 font-medium text-gray-400">Powered by Shynr</a>
                                        </div>
                                    }
                                </div>
                                <hr />
                            </div>
                            {widget_summary_bar === "left" ?
                                <div className="md:col-start-1 md:col-span-3 shadow-md rounded-xl p-4 bg-white dark:bg-gray-800 relative overflow-hidden" style={{ backgroundColor: background_color, maxHeight: "300px" }}>

                                    <div>
                                        <label className="uppercase text-gray-600 text-md font-medium tracking-wide" style={{ color: primary_color }}>Overall ratings</label>
                                        <div className="mt-2">
                                            <label className="text-5xl font-bold block" style={{ color: primary_color }}>{overall_rating}</label>
                                            <span className="text-gray-400 text-lg font-normal mb-3" style={{ color: primary_color }}>Based on  {CommonConfig.isConvert(active_product_reviews_count)} reviews</span>
                                            <div className="mt-3">
                                                {fields}
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">5 Star</label>
                                                <div className="relative w-100">
                                                    <div className="overflow-hidden h-4 text-xs flex rounded-full bg-green-200">
                                                        <div style={{ width: Number(star_5_count) / Number(active_product_reviews_count) * 100 + '%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                                    </div>
                                                </div>
                                                <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{CommonConfig.isConvert(star_5_count)}</label>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">4 Star</label>
                                                <div className="relative w-100">
                                                    <div className="overflow-hidden h-4 text-xs flex rounded-full bg-green-200">
                                                        <div style={{ width: Number(star_4_count) / Number(active_product_reviews_count) * 100 + '%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                                    </div>
                                                </div>
                                                <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{CommonConfig.isConvert(star_4_count)}</label>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">3 Star</label>
                                                <div className="relative w-100">
                                                    <div className="overflow-hidden h-4 text-xs flex rounded-full bg-green-200">
                                                        <div style={{ width: Number(star_3_count) / Number(active_product_reviews_count) * 100 + '%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                                    </div>
                                                </div>
                                                <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{CommonConfig.isConvert(star_3_count)}</label>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">2 Star</label>
                                                <div className="relative w-100">
                                                    <div className={progressbar_theme === "single" ? "overflow-hidden h-4 text-xs flex rounded-full bg-green-200" : "overflow-hidden h-4 text-xs flex rounded-full bg-yellow-200"}>
                                                        <div style={{ width: Number(star_2_count) / Number(active_product_reviews_count) * 100 + '%' }} className={progressbar_theme === "single" ?
                                                            "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                                                            : "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{CommonConfig.isConvert(star_2_count)}</label>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label className="text-gray-400 text-lg font-normal text-nowrap mr-2.5">1 Star</label>
                                                <div className="relative w-100">
                                                    <div className={progressbar_theme === "single" ? "overflow-hidden h-4 text-xs flex rounded-full bg-green-200" : "overflow-hidden h-4 text-xs flex rounded-full bg-red-200"}>
                                                        <div style={{ width: Number(star_1_count) / Number(active_product_reviews_count) * 100 + '%' }} className={progressbar_theme === "single" ? "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500" : "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"}></div>
                                                    </div>
                                                </div>
                                                <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{CommonConfig.isConvert(star_1_count ? star_1_count : 0)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mb-4 mt-4" />
                                    <div className="mt-1 d-none">
                                        <label className="uppercase text-gray-600 text-md font-medium tracking-wide mb-3">Frequent mentions</label>
                                        <div className="d-flex flex-wrap mt-2">
                                            {tags.map((x, idx) => {
                                                return (<span className="px-3 py-1 mr-2 mb-2 text-12 rounded-full text-black-500 border border-black-500" style={{ color: primary_color }} key={idx}>
                                                    {x.tags}
                                                </span>)
                                            })}
                                            {/* gg */}
                                        </div>
                                    </div>
                                    <hr className="mb-4 mt-4 d-none" />
                                    {media_count > 1 ?
                                        <div className="mt-1 d-none">
                                            <label className="uppercase text-gray-500 text-lg font-medium tracking-wide mb-3">Customer media ({media_count})</label>

                                            {customer_photo === "1" ?
                                                <div className="grid grid-cols-3 gap-4 mt-2">
                                                    {CustomerImages.map((x, i) => {
                                                        if (i < 6) {
                                                            return (
                                                                <div>
                                                                    <img src={x}
                                                                        onError={() => {
                                                                            var tempImg = CustomerImages
                                                                            CustomerImages[i] = ImgNotFound
                                                                            this.setState({ CustomerImages: tempImg })
                                                                        }}
                                                                        alt="Image" className="w-full h-36 object-cover overflow-hidden" />
                                                                </div>

                                                            )
                                                        }
                                                    })}
                                                    {/* {media1 === null ? null : <div>
                                                <img src={media1} alt="Image" className="w-full h-36 object-cover overflow-hidden" />
                                            </div>}
                                            {media2 === null ? null : <div>
                                                <img src={media2} alt="Image" className="w-full h-36 object-cover overflow-hidden" />
                                            </div>}
                                            {media3 === null ? null : <div>
                                                <img src={media3} alt="Image" className="w-full h-36 object-cover overflow-hidden" />
                                            </div>}*/}
                                                    {/* {media4 === null ? null :  */}
                                                    {/* <div className="relative">
                                                <div className="black-overlay"></div>
                                                <span className="absolute text-white top-1/3 left-1/4 font-medium text-lg">{media_count}</span>
                                                <img src={media4} alt="Image" className="w-full h-36 object-cover overflow-hidden" />
                                            </div> */}
                                                    {/* }  */}
                                                    {CustomerImages.length > 5 ?
                                                        <div className="relative">
                                                            <div className="black-overlay"></div>
                                                            <span className="absolute text-white top-1/3 text-20 left-1/4 font-medium text-lg ml-4">+{Number(media_count) - 5}</span>
                                                            <img src={CustomerImages[6]} alt="Image" className="w-full h-36 object-cover overflow-hidden" />
                                                        </div>
                                                        : null}
                                                </div>

                                                : null}
                                        </div>
                                        : null}
                                </div>
                                :
                                widget_summary_bar === "top" ?
                                    <div className="shadow-md rounded-xl p-4 bg-white dark:bg-gray-800 relative overflow-hidden" style={{ backgroundColor: background_color }}>
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-start-1 col-span-12 md:col-start-1 md:col-span-3 sm:col-start-1 sm:col-span-6">
                                                <label className="uppercase text-gray-600 text-md font-medium tracking-wide " style={{ color: primary_color }}>Overall ratings</label>
                                                <div className="mb-3">
                                                    <label className="text-5xl font-bold block" style={{ color: primary_color }}>{overall_rating}</label>
                                                    <span className="text-gray-400 text-lg font-normal mb-3" style={{ color: primary_color }}>Based on {CommonConfig.isConvert(active_product_reviews_count)} reviews</span>
                                                    <div className="mt-3">
                                                        {fields}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-span-12 md:col-start-4 md:col-span-5 sm:col-start-7 sm:col-span-12">
                                                <div className="mt-3">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">5 Star</label>
                                                        <div className="relative w-100">
                                                            <div className="overflow-hidden h-4 text-xs flex rounded-full bg-green-200">
                                                                <div style={{ width: Number(star_5_count) / Number(active_product_reviews_count) * 100 + '%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                                            </div>
                                                        </div>
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{star_5_count}</label>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">4 Star</label>
                                                        <div className="relative w-100">
                                                            <div className="overflow-hidden h-4 text-xs flex rounded-full bg-green-200">
                                                                <div style={{ width: Number(star_4_count) / Number(active_product_reviews_count) * 100 + '%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                                            </div>
                                                        </div>
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{star_4_count}</label>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">3 Star</label>
                                                        <div className="relative w-100">
                                                            <div className="overflow-hidden h-4 text-xs flex rounded-full bg-green-200">
                                                                <div style={{ width: Number(star_3_count) / Number(active_product_reviews_count) * 100 + '%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                                            </div>
                                                        </div>
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{star_3_count}</label>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap mr-2">2 Star</label>
                                                        <div className="relative w-100">
                                                            <div className={progressbar_theme === "single" ? "overflow-hidden h-4 text-xs flex rounded-full bg-green-200" : "overflow-hidden h-4 text-xs flex rounded-full bg-yellow-200"}>
                                                                <div style={{ width: Number(star_2_count) / Number(active_product_reviews_count) * 100 + '%' }} className={progressbar_theme === "single" ?
                                                                    "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                                                                    : "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{star_2_count}</label>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap mr-2.5">1 Star</label>
                                                        <div className="relative w-100">
                                                            <div className={progressbar_theme === "single" ? "overflow-hidden h-4 text-xs flex rounded-full bg-green-200" : "overflow-hidden h-4 text-xs flex rounded-full bg-red-200"}>
                                                                <div style={{ width: Number(star_1_count) / Number(active_product_reviews_count) * 100 + '%' }} className={progressbar_theme === "single" ? "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500" : "w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"}></div>
                                                            </div>
                                                        </div>
                                                        <label className="text-gray-400 text-lg font-normal text-nowrap ml-2">{star_1_count ? star_1_count : 0}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-7 md:col-span-6 d-none">
                                                <label className="uppercase text-gray-500 text-md font-medium tracking-wide mb-3">Frequent mentions</label>
                                                <div className="d-flex flex-wrap mt-2">
                                                    {tags.map((x, idx) => {
                                                        return (<span className="px-3 py-1 mr-2 mb-2 text-lg rounded-full text-black-500 border border-black-500" style={{ color: primary_color }} key={idx}>
                                                            {x.tags}
                                                        </span>)
                                                    })}
                                                    {/* <span className="px-3 py-1 mr-2 mb-2 text-base rounded-full text-black-500 border border-black-500">
                                        Tag name (4)
                            </span>
                                    <span className="px-3 py-1 mr-2 mb-2 text-base rounded-full text-black-500 border border-black-500">
                                        Tag (4)
                            </span>
                                    <span className="px-3 py-1 mr-2 mb-2 text-base rounded-full text-black-500 border border-black-500">
                                        Tag name (2)
                            </span>
                                    <span className="px-3 py-1 mr-2 mb-2 text-base rounded-full text-black-500 border border-black-500">
                                        Login tag name (4)
                            </span> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    : null
                            }
                            <div className={widget_summary_bar === "left" ? "md:col-start-4 md:col-span-9" : "md:col-start-5 md:col-span-12"}>
                                <div className="flex items-center justify-between mt-4 px-0 mb-3">
                                    <label className="uppercase text-xl font-medium tracking-wide text-gray-400">Reviews ({CommonConfig.isConvert(active_product_reviews_count)})</label>
                                    <div className="flex items-center">
                                        {productListReviews.length > 0 ?
                                            <div className="productReviewSelect">
                                                {/* <Select
                                        labelInline
                                        label="Sort by:"
                                    /> */}
                                                <Menu as="div" className="relative inline-block text-left">
                                                    {({ open }) => (
                                                        <>
                                                            <div>
                                                                <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 text-nowrap focus:outline-none">
                                                                    {!sortBy ? "Sort By" :
                                                                        <div>Sort by:&nbsp;
                                                                            <span style={{ color: "#3E7BFA" }}>{sortBy}</span>
                                                                        </div>
                                                                    }
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>

                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items
                                                                    static
                                                                    className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                >
                                                                    <div className="py-1">
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(
                                                                                        sortBy === "Newest" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 w-100 text-left py-2 text-md focus:outline-none'
                                                                                    )}
                                                                                    onClick={() => {
                                                                                        this.setState({ sortByValue: 'newest', sortBy: "Newest" })
                                                                                        this.getProductReviews(this.state.ProductID, "newest", this.state.max_review_display ? Number(this.state.max_review_display) : 25, 1)
                                                                                    }}
                                                                                >
                                                                                    Newest
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(
                                                                                        sortBy === "Oldest" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 py-2 w-100 text-left text-md focus:outline-none'
                                                                                    )}
                                                                                    onClick={() => {
                                                                                        this.setState({ sortByValue: 'oldest', sortBy: "Oldest" })
                                                                                        this.getProductReviews(this.state.ProductID, "oldest", this.state.max_review_display ? Number(this.state.max_review_display) : 25, 1)
                                                                                    }}
                                                                                >
                                                                                    Oldest
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(
                                                                                        sortBy === "High to Low" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 py-2 w-100 text-left text-md focus:outline-none'
                                                                                    )}
                                                                                    onClick={() => {
                                                                                        this.setState({ sortByValue: 'high_to_low', sortBy: "High to Low" })
                                                                                        this.getProductReviews(this.state.ProductID, "high_to_low", this.state.max_review_display ? Number(this.state.max_review_display) : 25, 1)
                                                                                    }}
                                                                                >
                                                                                    High to Low
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(
                                                                                        sortBy === "Low to High" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 py-2 w-100 text-left text-md focus:outline-none'
                                                                                    )}
                                                                                    onClick={() => {
                                                                                        this.setState({ sortByValue: 'low_to_high', sortBy: "Low to High" })
                                                                                        this.getProductReviews(this.state.ProductID, "low_to_high", this.state.max_review_display ? Number(this.state.max_review_display) : 25, 1)
                                                                                    }}
                                                                                >
                                                                                    Low to High
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </>
                                                    )}
                                                </Menu>
                                            </div>
                                            : null}
                                        {write_review_button === "1" ?
                                            <button onClick={() => window.parent.postMessage('writereviewopen', '*')} type="button" className="py-3 px-4 ml-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-xl font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                                                style={{ backgroundColor: this.state.btn_color }}
                                            // onClick={(e) => this.props.history.push('/storefront/writereview')}
                                            >
                                                Write a Review
                                            </button>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className={widget_design === "list" ? "" : "gap-6 grid lg:grid-cols-4 md:grid-cols-3 mx-auto sm:grid-cols-2 grid-cols-1"}>
                                    {productListReviews.map((x, idx) => {
                                        var firstName = x.reviewer_name.split(' ').slice(0, 1).join(' ');
                                        var lastName = x.reviewer_name.split(' ').slice(-1).join(' ');
                                        var initials = firstName.charAt(0) + lastName.charAt(0);
                                        var product_fields = []
                                        var OutLinedStar = 0;
                                        if (x.rating) {
                                            var blnHalfStar = (Math.floor(parseFloat(x.rating).toFixed(1)) < parseFloat(x.rating).toFixed(1));
                                            for (let i = 1; i <= Math.floor(parseFloat(x.rating).toFixed(1)); i++) {
                                                product_fields.push(<FontAwesomeIcon icon={faStar} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                                            }
                                            if (blnHalfStar) {
                                                product_fields.push(<FontAwesomeIcon icon={faStarHalfAlt} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                                                OutLinedStar = 5 - Math.floor(parseFloat(x.rating).toFixed(1)) - 1
                                            }
                                            else {
                                                OutLinedStar = 5 - Math.floor(parseFloat(x.rating).toFixed(1));
                                            }

                                            if (5 - Math.floor(parseFloat(x.rating).toFixed(1)) !== 0) {
                                                for (let i = 1; i <= OutLinedStar; i++) {
                                                    product_fields.push(<FontAwesomeIcon icon={faStarO} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                                                }
                                            }
                                        }
                                        return (
                                            widget_design === "list" ?
                                                <>
                                                    <div className="shadow-md rounded-xl review-popup p-4 bg-white dark:bg-gray-800 relative px-3 mt-3" style={{ backgroundColor: background_color }} key={idx}>
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex items-center justify-between w-full flex-wrap">
                                                                <div className="flex items-center">
                                                                    <FontAwesomeIcon icon={faStar} style={{ color: this.state.rating_star_color, fontSize: '18px' }} />
                                                                    <div className="ml-2 ">
                                                                        <span className="text-3xl font-bold" style={{ color: primary_color }}>{x.rating}</span>
                                                                        <span className="text-3xl font-normal" style={{ color: primary_color }}>/5</span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex items-center">
                                                                        <div className="cursorp ml-4 items-center flex" onClick={(e) => {
                                                                            this.upvoteProduct(x, idx)
                                                                        }}>
                                                                            <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                                                            <label className="cursorp text-gray-600 text-xl font-medium">helpful ({CommonConfig.isConvert(x.upvote_count ? x.upvote_count : 0)})</label>
                                                                        </div>
                                                                        <div className="cursorp ml-4 items-center flex" onClick={(e) => {
                                                                            this.downvoteProduct(x, idx)
                                                                        }}>
                                                                            <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                                                            <label className="cursorp text-gray-600 text-xl font-medium">({CommonConfig.isConvert(x.downvote_count ? x.downvote_count : 0)})</label>
                                                                        </div>
                                                                    </div>
                                                                    {share_button === "1" ?
                                                                        <div className="ml-3 items-center flex">
                                                                            <Menu as="div" className="relative inline-block text-left">
                                                                                <div>
                                                                                    <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 text-gray-700 h-10">
                                                                                        <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                                                                                    </Menu.Button>
                                                                                </div>

                                                                                <Transition
                                                                                    as={Fragment}
                                                                                    enter="transition ease-out duration-100"
                                                                                    enterFrom="transform opacity-0 scale-95"
                                                                                    enterTo="transform opacity-100 scale-100"
                                                                                    leave="transition ease-in duration-75"
                                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                                    leaveTo="transform opacity-0 scale-95"
                                                                                >
                                                                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: '999' }}>
                                                                                        <div className="py-1">
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        // href={"https://www.facebook.com/sharer/sharer.php?u=https://fwidget.equatorsolution.com/social-share/" + x.id}
                                                                                                        href={"https://www.facebook.com/sharer/sharer.php?u=https://s.shynr.co/share/" + x.id}
                                                                                                        target="_blank"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-xl font-medium'
                                                                                                        )}
                                                                                                    >
                                                                                                        <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href={"https://twitter.com/intent/tweet?url=https://s.shynr.co/share/" + x.id}
                                                                                                        target="_blank"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-xl font-medium'
                                                                                                        )}
                                                                                                    >
                                                                                                        <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4" onClick={() => window.parent.postMessage(`reviewDetailsOpen?reviewer_id=${x.id}&isMediaExist=${x.media1new ? true : false}`, '*')} >
                                                            <div className="relative">
                                                                <label className="text-2xl text-black-300 font-semibold review-title" style={{ color: primary_color }}>{x.review_title}</label>
                                                                <p className="text-gray-600 font-normal multiple-line-trancate" style={{ color: primary_color }}>{
                                                                    x.review_body.length > CommonConfig.ReadMoreLen && !x.IsReadMoreSelected ? ReactHtmlParser(x.review_body.substr(0, CommonConfig.ReadMoreLen) + "....") :
                                                                        ReactHtmlParser(x.review_body)}</p>
                                                                {x.review_body.length > CommonConfig.ReadMoreLen ?
                                                                    !x.IsReadMoreSelected ?
                                                                        <span className="bottom-0 right-0 action-popover-2 flex justify-end absolute" onClick={() => {
                                                                            var tempProductReviewList = productListReviews;
                                                                            tempProductReviewList[idx]['IsReadMoreSelected'] = true;
                                                                            this.setState({ productListReviews: tempProductReviewList })
                                                                        }}>
                                                                            <Link to="#" style={{backgroundColor: background_color}} className="email-text pl-2">Read more</Link>
                                                                        </span>
                                                                        :
                                                                        <span className="bottom-0 right-0 action-popover-2 flex justify-end absolute" onClick={() => {
                                                                            var tempProductReviewList = productListReviews;
                                                                            tempProductReviewList[idx]['IsReadMoreSelected'] = true;
                                                                            this.setState({ productListReviews: tempProductReviewList })
                                                                        }}>
                                                                            <Link to="#" style={{backgroundColor: background_color}} className="email-text pl-2">Read more</Link>
                                                                        </span>
                                                                    : null
                                                                }
                                                            </div>
                                                            {/* <div className='flex'>
                                                                {x.media1 && CommonConfig.findExtesionFromURL(x.media1) == "mp4" ?
                                                                    <div className="video-player-thumbnail mr-3">
                                                                        <ReactPlayer style={{ borderRadius: '4px', overflow: 'hidden' }} width="208px" height="123px" url={x.media1} controls={true} />
                                                                    </div> : null}

                                                                {x.media2 && CommonConfig.findExtesionFromURL(x.media2) == "mp4" ?
                                                                    <div className="video-player-thumbnail mr-3">
                                                                        <ReactPlayer style={{ borderRadius: '4px', overflow: 'hidden' }} width="208px" height="123px" url={x.media2} controls={true} />
                                                                    </div> : null}
                                                                {x.media3 && CommonConfig.findExtesionFromURL(x.media3) == "mp4" ?
                                                                    <div className="video-player-thumbnail mr-3">
                                                                        <ReactPlayer style={{ borderRadius: '4px', overflow: 'hidden' }} width="208px" height="123px" url={x.media3} controls={true} />
                                                                    </div> : null}

                                                                {x.media4 && CommonConfig.findExtesionFromURL(x.media4) == "mp4" ?
                                                                    <div className="video-player-thumbnail mr-3">
                                                                        <ReactPlayer style={{ borderRadius: '4px', overflow: 'hidden' }} width="208px" height="123px" url={x.media4} controls={true} />
                                                                    </div> : null
                                                                }
                                                            </div> */}

                                                            {customer_photo === "1" ?
                                                                (x.media1new === null && x.media2new === null && x.media3new === null && x.media4new === null) ? null :
                                                                    <div className="flex flex-wrap">
                                                                        {/* {x.media1 !== null && CommonConfig.findExtesionFromURL(x.media1) != "mp4" ?
                                                                            <div>
                                                                                <img src={x.media1} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }
                                                                                } />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {x.media2 !== null && CommonConfig.findExtesionFromURL(x.media2) != "mp4" ?
                                                                            <div>
                                                                                <img src={x.media2} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {x.media3 !== null && CommonConfig.findExtesionFromURL(x.media3) != "mp4" ?
                                                                            <div>
                                                                                <img src={x.media3} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {x.media4 !== null && CommonConfig.findExtesionFromURL(x.media4) != "mp4" ?
                                                                            <div>
                                                                                <img src={x.media4} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        } */}
                                                                        {x.media1 !== null ?
                                                                            <div className='relative'>
                                                                                {!CommonConfig.isEmpty(x.media1) && CommonConfig.findExtesionFromURL(x.media1) && (CommonConfig.findExtesionFromURL(x.media1) == 'mp4' || CommonConfig.findExtesionFromURL(x.media1) == '3gp') ?
                                                                                    <div className='absolute playIcon'>
                                                                                        <FontAwesomeIcon icon={faPlay} className="ml-2 text-30 font-normal" style={{ color: '#FFFFFF' }} />
                                                                                    </div> : null}

                                                                                <img src={x.media1new} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }
                                                                                } />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {x.media2 !== null ?
                                                                            <div className='relative'>
                                                                                {!CommonConfig.isEmpty(x.media2) && CommonConfig.findExtesionFromURL(x.media2) && (CommonConfig.findExtesionFromURL(x.media2) == 'mp4' || CommonConfig.findExtesionFromURL(x.media2) == '3gp') ?
                                                                                    <div className='absolute playIcon'>
                                                                                        <FontAwesomeIcon icon={faPlay} className="ml-2 text-30 font-normal" style={{ color: '#FFFFFF' }} />
                                                                                    </div> : null}
                                                                                <img src={x.media2new} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {x.media3 !== null ?
                                                                            <div className='relative'>
                                                                                {!CommonConfig.isEmpty(x.media3) && CommonConfig.findExtesionFromURL(x.media3) && (CommonConfig.findExtesionFromURL(x.media3) == 'mp4' || CommonConfig.findExtesionFromURL(x.media3) == '3gp') ?
                                                                                    <div className='absolute playIcon'>
                                                                                        <FontAwesomeIcon icon={faPlay} className="ml-2 text-30 font-normal" style={{ color: '#FFFFFF' }} />
                                                                                    </div> : null}
                                                                                <img src={x.media3new} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {x.media4 !== null ?
                                                                            <div className='relative'>
                                                                                {!CommonConfig.isEmpty(x.media4) && CommonConfig.findExtesionFromURL(x.media4) && (CommonConfig.findExtesionFromURL(x.media4) == 'mp4' || CommonConfig.findExtesionFromURL(x.media4) == '3gp') ?
                                                                                    <div className='absolute playIcon'>
                                                                                        <FontAwesomeIcon icon={faPlay} className="ml-2 text-30 font-normal" style={{ color: '#FFFFFF' }} />
                                                                                    </div> : null}
                                                                                <img src={x.media4new} alt="Image" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                                                                                    ev.target.src = ImgNotFound
                                                                                }} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>

                                                                : null}
                                                            <div className=" flex items-center mt-4">
                                                                {x.public_reply === null ? null : <label className="text-black-600 text-xl font-semibold">Reply from shop</label>}
                                                                {x.public_replied_at === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                                                </span>}
                                                                {review_publish_date === "1" ?
                                                                    <p className="text-gray-400 text-xl font-normal ml-3">
                                                                        {x.public_replied_at ? moment(x.public_replied_at).fromNow() : ""}
                                                                        {/* {x.public_replied_at ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(x.public_replied_at), timeZone)).fromNow() : '-'} */}
                                                                    </p>
                                                                    : null
                                                                }
                                                            </div>
                                                            {x.public_reply === null ? null : <div className="mt-2">
                                                                <p className="text-gray-600 font-normal" style={{ color: primary_color }}>{x.public_reply}</p>
                                                            </div>}
                                                        </div>
                                                        <div style={{ backgroundColor: background_color }} className='review-popup-arrow'></div>
                                                    </div>
                                                    <div className="flex items-center justify-between mt-4 px-3">
                                                        <div className=" flex items-center">
                                                            <div className="rounded-full w-12 h-12 overflow-hidden d-none">
                                                                {/* <img alt="" src={rvwPro} className="object-cover" /> */}
                                                            </div>
                                                            <div>
                                                                <span class="text-gray-700 font-medium text-16 flex items-center justify-center bg-gray-300 w-16 h-16 pt-1 mr-2 rounded-full">{initials.toUpperCase()}</span>
                                                            </div>
                                                            {reviewer_name === "1" ?
                                                                <label className="text-gray-400 text-xl font-semibold ml-3 customer-name">{x.reviewer_name}
                                                                </label>
                                                                : null
                                                            }
                                                            <span className="rounded-full h-2 w-2 bg-gray-400 ml-3"></span>
                                                            {review_publish_date === "1" ?
                                                                <p className="text-gray-400 text-xl font-normal ml-3">
                                                                    {x.created_at ? moment(x.created_at).fromNow() : ""}
                                                                    {/* {x.created_at ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(x.created_at), timeZone)).fromNow() : '-'} */}
                                                                </p>
                                                                : null
                                                            }
                                                            {this.state.reviewer_country_flag == '1' ?
                                                                <div>&nbsp;&nbsp;<span className={`mt-2 ml-2 flag-icon flag-icon-${x.reviewer_country ? x.reviewer_country.toLowerCase() : null}`}></span></div> : null}
                                                        </div>
                                                        {x.is_verified === "1" ?
                                                            <div>
                                                                <span className="rounded-full uppercase text-white text-base font-medium px-3 py-2 leading-none flex items-center">
                                                                    <img src={verifyBadgeGreen} alt="" className="text-light-green w-8 h-8 justify-between" />
                                                                    &nbsp; <span className="text-light-green text-nowrap uppercase text-12">Verified purchase</span>
                                                                </span>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </>

                                                :

                                                <div className="shadow-lg rounded-xl bg-white overflow-hidden1 mb-3" style={{ backgroundColor: background_color }}>
                                                    <div onClick={() => window.parent.postMessage(`reviewDetailsOpen?reviewer_id=${x.id}`, '*')}>
                                                        {customer_photo === "1" ?
                                                            <div className="relative">
                                                                {/* {CommonConfig.findExtesionFromURL(x.media1) && (CommonConfig.findExtesionFromURL(x.media1) == 'mp4' || CommonConfig.findExtesionFromURL(x.media1) == '3gp') ?
                                                                    <div className="video-player-thumbnail">
                                                                        <ReactPlayer style={{ borderRadius: '4px', overflow: 'hidden' }} width="100%" height="100%" url={x.media1} controls={true} />
                                                                        <img alt="" src={CommonConfig.generateVideoThumbnail(x.media1).then(i => { return i })} className="object-cover overflow-hidden w-full h-80" onError={(ev) => {
                                                                            ev.target.src = ImgNotFound
                                                                        }} />
                                                                    </div>
                                                                    : */}
                                                                {!CommonConfig.isEmpty(x.media1) && CommonConfig.findExtesionFromURL(x.media1) && (CommonConfig.findExtesionFromURL(x.media1) == 'mp4' || CommonConfig.findExtesionFromURL(x.media1) == '3gp') ?
                                                                    <div className='absolute playIcon'>
                                                                        <FontAwesomeIcon icon={faPlay} className="ml-2 text-30 font-normal" style={{ color: '#FFFFFF' }} />
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                <img alt="" src={x.media1new} className="object-cover overflow-hidden w-full h-80" onError={(ev) => {
                                                                    ev.target.src = ImgNotFound
                                                                }} />
                                                                {/* } */}

                                                                <div className="top-3 right-3 w-8 absolute">
                                                                    {/* <FontAwesomeIcon icon={faTimesCircle} className="text-gray-300 font-normal" /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        <div className="p-4 border-bottom" style={{ height: "220px" }}>
                                                            <div className="flex items-center mb-2">
                                                                <div className="">
                                                                    <span className="text-5xl font-bold" style={{ color: primary_color }}>{x.rating}</span>
                                                                    <div className="mt-1">
                                                                        {product_fields}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <label className="text-black-600 font-semibold mb-2 review-title" style={{ color: primary_color }}>{x.review_title}</label>
                                                                <p style={{ height: '60px', color: primary_color }} className="text-gray-600 font-normal mb-2 multiple-line-trancate">{ReactHtmlParser(x.review_body)}</p>
                                                            </div>
                                                            <div className="mt-3 flex items-end justify-between">
                                                                <div className="w-100">
                                                                    <label className="customer-name font-medium text-14">{x.reviewer_name}
                                                                        {this.state.reviewer_country_flag && this.state.reviewer_country_flag == '1' ?
                                                                            <span className={`mt-2 ml-2 flag-icon flag-icon-${x.reviewer_country ? x.reviewer_country.toLowerCase() : null}`}></span> : null}</label>
                                                                    <div className="flex mt-2 justify-between">
                                                                        {review_publish_date === "1" ? <p className={x.created_at ? "capitalize text-gray-400 text-12 font-normal" : "d-none"}>
                                                                            {x.created_at ? moment(x.created_at).fromNow() : ""}
                                                                            {/* {x.created_at ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(x.created_at), timeZone)).fromNow() : '-'} */}
                                                                        </p> : null}
                                                                        {x.is_verified === "1" ?
                                                                            <div className="flex items-center">
                                                                                <img src={verifyBadgeGreen} alt="" className="text-light-green w-8 h-8 justify-between" />
                                                                                &nbsp; <span className="text-light-green text-nowrap uppercase text-12">Verified purchase</span>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="p-4 flex items-center justify-between">
                                                        <div className="flex flex-wrap items-center justify-content-around">
                                                            <div className="cursorp ml-2 flex items-center" onClick={(e) => {
                                                                this.upvoteProduct(x, idx)
                                                            }}>
                                                                <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                                                <label className="cursorp text-gray-500 text-12 font-medium text-nowrap">helpful ({CommonConfig.isConvert(x.upvote_count ? x.upvote_count : 0)})</label>
                                                            </div>
                                                            <div className="cursorp ml-2 flex items-center" onClick={(e) => {
                                                                this.downvoteProduct(x, idx)
                                                            }}>
                                                                <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                                                <label className="cursorp text-gray-500 text-12 font-medium text-nowrap">({CommonConfig.isConvert(x.downvote_count ? x.downvote_count : 0)})</label>
                                                            </div>
                                                            {share_button === "1" ? <div className="flex items-center">
                                                                <Menu as="div" className="relative inline-block text-left">
                                                                    <div>
                                                                        <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 text-gray-700 h-10">
                                                                            <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                                                                        </Menu.Button>
                                                                    </div>

                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: '999' }}>
                                                                            <div className="py-1">
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <a
                                                                                            // href={"https://www.facebook.com/sharer/sharer.php?u=https://fwidget.equatorsolution.com/social-share/" + x.id}
                                                                                            href={"https://www.facebook.com/sharer/sharer.php?u=https://s.shynr.co/share/" + x.id}
                                                                                            target="_blank"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-xl font-medium'
                                                                                            )}
                                                                                        >
                                                                                            <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                                                                                        </a>
                                                                                    )}
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <a
                                                                                            href={"https://twitter.com/intent/tweet?url=https://s.shynr.co/share/" + x.id}
                                                                                            target="_blank"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-xl font-medium'
                                                                                            )}
                                                                                        >
                                                                                            <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                                                                                        </a>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                        )
                                    })
                                    }
                                </div>
                                {productListReviews.length <= 0 ?
                                    <div className="flex justify-center my-3"><span>No reviews yet</span></div>
                                    : this.state.loadMorepage === this.state.LoadMoreTotal ? null :
                                    
                                        <div className="flex justify-center my-3">
                                            <button style={isHoverActive ? { border: '1px solid' + this.state.btn_color, backgroundColor: this.state.btn_color, color: 'white' } : { border: '1px solid' + this.state.btn_color, color: this.state.btn_color }}  className="flex items-center text-blue-500 bg-transparent border border-solid  hover:bg-blue-500 text-md hover:text-white active:bg-blue-600 font-medium px-10 py-3 rounded-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                                            onMouseOver={() => this.setState({isHoverActive: true})}
                                            onMouseOut={() => this.setState({isHoverActive: false})}
                                            onClick={() => {
                                                this.setState({ loadMorepage: this.state.loadMorepage + 1, loading: true, loadMoreCount: Number(this.state.loadMoreCount) + Number(this.state.max_review_display) })
                                                this.getProductReviews(this.state.ProductID, this.state.sortByValue, Number(this.state.loadMoreCount) + Number(this.state.max_review_display), 1)
                                            }}>
                                                {this.state.loading ? <div style={{borderTopColor: this.state.btn_color}} class="mr-3 loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div> : null}
                                                Load More</button>
                                        </div>
                                }
                            </div>
                        </div>
                    }
                    {/* left view over */}
                    <br />

                    {/* top view */}


                </div>
        )
    }
}
