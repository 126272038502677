import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faStar, faThumbsUp, faThumbsDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import rvwPro from "../../assets/img/Illustration.png";
import * as Constants from '../../utils/graphql_queries.js';
import { client, widgetSettingsShopURL } from '../../utils/config';
import ImageGallery from 'react-image-gallery';
import moment from "moment";
import { Link } from 'react-router-dom';
import CommonConfig from '../../utils//constant';
import verifyBadge from "../../assets/img/verify-badge-white.svg";
import verifyBadgeGreen from "../../assets/img/verify-badge.svg";
import ErrorNotFound from '../404Error/404Error';
import Loading from '../Loading/Loading';
import WebFont from 'webfontloader';
import MetaTags from 'react-meta-tags';

const initialState = {

    product_title_product: "",
    rating_product: "",
    is_verified: "",
    review_title_product: "",
    review_body_product: "",
    reviewer_name_product: "",
    reviewer_email_product: "",
    media_1_product: "",
    media_2_product: "",
    media_3_product: "",
    media_4_product: "",
    reviewer_country_product: "",
    upvote_count_product: "",
    downvote_count_product: "",
    status_product: "",
    created_at_product: "",
    public_reply_product: "",
    public_replied_at_product: "",
    title_product: "",
    handle_product: "",
    IsReadMoreReviewSelected: false,
    IsReadMoreReplySelected: false,
    initials: 'JD',
    review_id: '',
    images: [],
    ProductID: '',
    ReviewerID: '',
    loading: false,
    timeZone: '',

}

export default class ShareReviewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        var url = new URL(window.location.href);
        var productid = url.searchParams.get("id");
        var reviewer_id = url.searchParams.get("reviewer_id");
        var widgetSettingsShopURL = ''
        if (url.searchParams.get("shop_url")) {
            widgetSettingsShopURL = url.searchParams.get("shop_url");
        }
        if (!CommonConfig.isEmpty(productid) && !CommonConfig.isEmpty(widgetSettingsShopURL)) {
            this.setState({ ProductID: productid, ReviewerID: reviewer_id })
            this.getWedigetsAdminSettings(widgetSettingsShopURL);
        } else {
            this.setState({ loading: false })
        }
    }


    getProductReviews = (id, sort, first, shop_id) => {
        var data = {
            id: id
        }
        client
            .query({
                query: Constants.VIEW_PRODUCT_REVIEW,
                variables: data,
            })
            .then(result => {

                var images = [];
                if (result.data.ProductReview.media1) {
                    images.push({
                        original: result.data.ProductReview.media1,
                        thumbnail: result.data.ProductReview.media1,
                    })
                }
                if (result.data.ProductReview.media2) {
                    images.push({
                        original: result.data.ProductReview.media2,
                        thumbnail: result.data.ProductReview.media2,
                    })
                }
                if (result.data.ProductReview.media3) {
                    images.push({
                        original: result.data.ProductReview.media3,
                        thumbnail: result.data.ProductReview.media3,
                    })
                }
                if (result.data.ProductReview.media4) {
                    images.push({
                        original: result.data.ProductReview.media4,
                        thumbnail: result.data.ProductReview.media4,
                    })
                }
                var firstName = result.data.ProductReview.reviewer_name.split(' ').slice(0, 1).join(' ');
                var lastName = result.data.ProductReview.reviewer_name.split(' ').slice(-1).join(' ');
                var initials = firstName.charAt(0) + lastName.charAt(0);
                this.setState({
                    product: result.data.ProductReview.product,
                    review_id: result.data.ProductReview.id,
                    product_title_product: result.data.ProductReview.product_title,
                    rating_product: result.data.ProductReview.rating,
                    is_verified: result.data.ProductReview.is_verified,
                    review_title_product: result.data.ProductReview.review_title,
                    review_body_product: result.data.ProductReview.review_body,
                    reviewer_name_product: result.data.ProductReview.reviewer_name,
                    reviewer_email_product: result.data.ProductReview.reviewer_email,
                    media_1_product: result.data.ProductReview.media1,
                    media_2_product: result.data.ProductReview.media2,
                    media_3_product: result.data.ProductReview.media3,
                    media_4_product: result.data.ProductReview.media4,
                    reviewer_country_product: result.data.ProductReview.reviewer_country,
                    upvote_count_product: result.data.ProductReview.upvote_count,
                    downvote_count_product: result.data.ProductReview.downvote_count,
                    status_product: result.data.ProductReview.status,
                    created_at_product: result.data.ProductReview.created_at,
                    public_reply_product: result.data.ProductReview.public_reply,
                    public_replied_at_product: result.data.ProductReview.public_replied_at,
                    title_product: result.data.ProductReview.product.title,
                    handle_product: result.data.ProductReview.product.handle,
                    initials: initials,
                    images: images
                })

                //setTimeout(()=>{ this.setState({loading : false}) }, 2000);
                this.setState({ loading: false })
            }).catch((error) => {
            });
    }

    getWedigetsAdminSettings = (widgetSettingsShopURL) => {
        fetch(widgetSettingsShopURL).then(res => { return res.json() }).then(result => {

            this.setState({
                background_color: result[0].background_color,
                card_widget: result[0].card_widget,
                customer_photo: result[0].customer_photo,
                display_customer_image_box: result[0].display_customer_image_box,
                floating_tab_background_color: result[0].floating_tab_background_color,
                floating_tab_font_color: result[0].floating_tab_font_color,
                floating_widget: result[0].floating_widget,
                floating_widget_position: result[0].floating_widget_position,
                frequent_mentions: result[0].frequent_mentions,
                hide_findshop_branding: result[0].hide_findshop_branding,
                hide_product_link: result[0].hide_product_link,
                hide_star_no_reviews: result[0].hide_star_no_reviews,
                max_review_display: result[0].max_review_display,
                page_widget: result[0].page_widget,
                primary_color: result[0].primary_color,
                product_widget: result[0].product_widget,
                progressbar_theme: result[0].progressbar_theme,
                rating_star_color: result[0].rating_star_color,
                review_publish_date: result[0].review_publish_date,
                reviewer_country_flag: result[0].reviewer_country_flag,
                reviewer_name: result[0].reviewer_name,
                reviewer_name_style: result[0].reviewer_name_style,
                share_button: result[0].share_button,
                shop_id: result[0].shop_id,
                social_proof_widget: result[0].social_proof_widget,
                social_proof_widget_delay: result[0].social_proof_widget_delay,
                social_proof_widget_position: result[0].social_proof_widget_position,
                social_proof_widget_style: result[0].social_proof_widget_style,
                title_widget: result[0].title_widget,
                title_widget_style: result[0].title_widget_style,
                widget_font: result[0].widget_font,
                widget_font_name: result[0].widget_font_name,
                widget_language: result[0].widget_language,
                widget_summary_bar: result[0].widget_summary_bar,
                write_review_button: result[0].write_review_button,
                timeZone: result[0].time_zone,
            })
            this.getProductReviews(this.state.ReviewerID, "oldest", 25, result[0].shop_id);
            WebFont.load({
                google: {
                    families: [result[0].widget_font]
                }
            })
        })
    }
    checkLikeUnlike(key, id) {
        var LikeUnlikes = JSON.parse(localStorage.getItem(key));

        if (LikeUnlikes != null && LikeUnlikes.includes(id)) {
            return false
        }

        if (LikeUnlikes != null) {
            LikeUnlikes.push(id);
        }
        else {
            LikeUnlikes = [id];
        }
        localStorage.setItem(key, JSON.stringify(LikeUnlikes));
        return true
    }
    upvoteProduct = async (id) => {
        var data = {
            "review_id": id
        }

        if (this.checkLikeUnlike("upvoteProduct", id)) {
            await client
                .mutate({
                    mutation: Constants.PRODUCT_UPVOTE,
                    variables: data
                })
                .then(async result => {
                    this.setState({
                        upvote_count_product: parseInt(this.state.upvote_count_product, 10) + 1
                    })
                    // console.log("result", result.data.ProductReviewUpvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }

    downvoteProduct = async (id) => {
        var data = {
            "review_id": id
        }
        if (this.checkLikeUnlike("downvoteProduct", id)) {
            await client
                .mutate({
                    mutation: Constants.PRODUCT_DOWNVOTE,
                    variables: data

                })
                .then(async result => {
                    this.setState({
                        downvote_count_product: parseInt(this.state.downvote_count_product, 10) + 1
                    })
                    // console.log("result", result.data.ProductReviewDownvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }
    render() {
        const { timeZone, loading, images, review_id, initials, primary_color, background_color, review_publish_date, share_button, hide_star_no_reviews, rating_product, review_title_product, review_body_product, reviewer_name_product, media_1_product, media_2_product, media_3_product, media_4_product, upvote_count_product, downvote_count_product, created_at_product, public_reply_product, public_replied_at_product, IsReadMoreReplySelected, IsReadMoreReviewSelected, reviewer_country_product, is_verified, rating_star_color, widget_font_name } = this.state;

        return (
            loading ?
                <Loading />
                :
                CommonConfig.isEmpty(this.state.ProductID) && CommonConfig.isEmpty(this.state.ReviewerID) ?
                    <ErrorNotFound />
                    :
                    <div className={"grid grid-cols-1 h-100 ml-2 mr-2 " + (images && images.length ? "md:grid-cols-2" : "md:grid-cols-1")} style={{ backgroundColor: background_color, fontFamily: widget_font_name }}>
                        {images && images.length ?
                            (<div className="bg-black">
                                <ImageGallery items={images} />
                            </div>) : null}
                        <MetaTags>
                            <meta charset="UTF-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <meta content="text/html;charset=utf-8" http-equiv="Content-Type" />
                            <meta content="utf-8" http-equiv="encoding" />

                            <meta property="og:title" content="Ayres Chambray rated ⭐ 4/5 by Ravi" />
                            <meta property="og:description" content="Overall2 good shopping experience. Customer service is great. They helped me setting up new hosting for my website." />
                            <meta name="twitter:card" content="summary" />
                            <meta property="twitter:title" content="Ayres Chambray rated ⭐ 4/5 by Ravi" />
                            <meta property="twitter:description" content="Overall1 good shopping experience. Customer service is great. They helped me setting up new hosting for my website." />
                            <meta property="og:type" content="website" />
                            <meta http-equiv="content-language" content="en-in" />
                            <meta property="og:image" content="https://app.shynr.co/media/61570800aef3f.jpeg" />
                            <meta property="twitter:image" content="https://app.shynr.co/media/61570800aef3f.jpeg" />
                        </MetaTags>
                        <div className="bg-white p-4">
                            <div className="flex items-center justify-between mb-3">
                                {hide_star_no_reviews === "0" ? <div className="flex items-center">
                                    <FontAwesomeIcon icon={faStar} style={{ color: rating_star_color, fontSize: '18px' }} />
                                    <div className="ml-2 ">
                                        <span className="text-3xl text-black-300 font-bold">{rating_product}</span><span className="text-3xl text-gray-400 font-normal">/5</span>
                                    </div>
                                </div> : null}
                                <div className="pt-1.5">
                                    <button onClick={() => window.parent.postMessage('reviewDetailsClose', '*')}>
                                        <FontAwesomeIcon icon={faTimes} style={{ color: '#5C5F62', fontSize: '20px' }} />
                                    </button>
                                </div>
                            </div>
                            <div className="relative">
                                <label className="text-black-400 text-2xl font-bold review-title" style={{ color: primary_color }}>{review_title_product}.</label>
                                <p className="text-gray-500 text-2xl font-normal mt-2" style={{ color: primary_color }}>{review_body_product}</p>
                            </div>
                            <br />
                            <div className="flex items-center">
                                {public_reply_product === null ? null : <label className="text-black-400 text-2xl font-bold">Reply from shop</label>}
                                {public_replied_at_product === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                </span>}
                                {review_publish_date === "1" ?
                                    <p className="text-gray-400 text-md font-normal ml-3">
                                        {public_replied_at_product ? moment(public_replied_at_product).fromNow() : ""}
                                        {/* {public_replied_at_product ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(public_replied_at_product), timeZone)).fromNow() : '-'} */}
                                    </p>
                                    : null
                                }
                            
                                
                            </div>
                            {public_reply_product === null ? null :
                                <div className="mt-2">
                                    <p className="text-gray-500 text-2xl font-normal mt-2" style={{ color: primary_color }}>{public_reply_product}</p>
                                </div>
                            }
                            <br />
                            <div className="flex items-center justify-between" >
                                <div className=" flex items-center">
                                    <div>
                                        <span class="text-gray-700 font-medium text-16 flex items-center justify-center bg-gray-300 w-14 h-14 mr-2 rounded-full">{initials}</span>
                                    </div>
                                    <label className="text-gray-400 text-xl font-semibold ml-3 customer-name">{reviewer_name_product}</label>
                                    {created_at_product === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                    </span>}
                                    {review_publish_date === "1" ?
                                        <p className="text-gray-400 text-xl font-normal ml-3">
                                            {created_at_product ? moment(created_at_product).fromNow() : ""}
                                            {/* {created_at_product ? moment(CommonConfig.ConvertTimeZone(CommonConfig.dateFormatUsingDefaultJS(created_at_product), timeZone)).fromNow() : '-'} */}
                                        </p>
                                        :
                                        null
                                    }
                                    {this.state.reviewer_country_flag == '1' ?
                                        <div>&nbsp;&nbsp;<span className={`mt-2 ml-2 flag-icon flag-icon-${reviewer_country_product ? reviewer_country_product.toLowerCase() : null}`}></span></div> : null}

                                </div>
                                {is_verified === "1" ?
                                    <div>
                                        <span className="rounded-full uppercase text-white text-base font-medium px-3 py-2 leading-none flex items-center">
                                            <img src={verifyBadgeGreen} alt="" className="text-light-green w-8 h-8 justify-between" />
                                            &nbsp; <span className="text-light-green text-nowrap uppercase text-12">Verified purchase</span>
                                        </span>
                                    </div>
                                    : null}
                                     {this.state.reviewer_country_flag == '1' ?
                                            <div>&nbsp;&nbsp;<span className={`mt-2 ml-2 flag-icon flag-icon-${reviewer_country_product ? reviewer_country_product.toLowerCase() : null}`}></span></div> : null}
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div className="cursorp ml-4 items-center flex" onClick={(e) => this.upvoteProduct(review_id)}>
                                        <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                        <label className="cursorp text-gray-600 text-xl font-medium">helpful ({CommonConfig.isConvert(upvote_count_product ? upvote_count_product : 0)})</label>
                                    </div>
                                    <div className="cursorp ml-4 items-center flex" onClick={(e) => this.downvoteProduct(review_id)}>
                                        <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                                        <label className="cursorp text-gray-600 text-xl font-medium">({CommonConfig.isConvert(downvote_count_product ? downvote_count_product : 0)})</label>
                                    </div>
                                </div>
                                {share_button === "1" ? <div>
                                    <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-3xl mr-2 font-normal" />
                                    <label className="text-gray-600 text-2xl font-medium">Share</label>
                                </div> : null}
                            </div>
                        </div>
                    </div>
        )
    }
}
