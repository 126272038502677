import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../routes.js";
import { AppProvider } from '@shopify/polaris';

export default function Dashboard(props) {

    const isAuthorized = localStorage.getItem("isAuthorized");

    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if ((prop.layout === "/storefront" || prop.layout === "/") && isAuthorized && prop.isAccessRequired) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                        render={props => (
                            <prop.component {...props} />
                        )}
                    />
                );
            } else if ((prop.layout === "/storefront" || prop.layout === "/") && !prop.isAccessRequired) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                        render={props => (
                            <prop.component {...props} />
                        )}
                    />
                );
            } else {
                return null;
            }
        });
    };

    // console.log("storefront", getRoutes(routes))

    return (
        <AppProvider>
            <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/storefront/productreview" />
            </Switch>
        </AppProvider>
    );
}
