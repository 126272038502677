import ProductReview from "../src/components/ProductReview/ProductReview.js";
import SocialProof from "../src/components/SocialProof/SocialProof.js";
// import TitleRating from "../src/components/TitleRating/TitleRating.js";  
import CustomerReviewPage from "../src/components/CustomerReviewPage/CustomerReviewPage.js";
import FloatingTab from "../src/components/FloatingTab/FloatingTab.js";
import ReviewDetails from "../src/components/ReviewDetails/ReviewDetails.js";
import ShareReviewDetails from "../src/components/ReviewDetails/ShareReviewDetails.js";
import WriteReview from "../src/components/WriteReview/WriteReview.js";
import EmailOrderReview from "../src/components/WriteReview/EmailOrderReview.js";
import successMessage from "../src/components/successMessage/successMessage.js";
import successMessageWriteReview from "../src/components/successMessage/successMessageWriteReview.js";
import errorMessage from "../src/components/ErrorMessage/errorMessage.js";
import UpdateProductReview from "../src/components/WriteReview/UpdateProductReview.js";
import UpdateShopReview from "../src/components/WriteReview/UpdateShopReview.js";
import SocialMetaData from "./components/SocialMetaData/SocialMetaData.js";

var dashRoutes = [


  {
    path: "/productreview",
    name: "ProductReview",
    component: ProductReview,
    parentname: "",
    invisible: true,
    layout: "/storefront",
    isAccessRequired: false
  },


  // {
  //   path: "/titleratings",
  //   name: "Title rating",
  //   component: TitleRating,
  //   parentname: "",
  //   invisible: false,
  //   select: true,
  //   layout: "/storefront",
  //   isAccessRequired: false
  // },
  {
    path: "/socialproof",
    name: "Social proof",
    component: SocialProof,
    parentname: "",
    invisible: false,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/customerreview",
    name: "Customer review page",
    component: CustomerReviewPage,
    parentname: "",
    invisible: false,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/floatingtab",
    name: "Floating Tab",
    component: FloatingTab,
    parentname: "",
    invisible: false,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/reviewdetails",
    name: "Review details",
    component: ReviewDetails,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/review",
    name: "Share Review details",
    component: ShareReviewDetails,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/writereview",
    name: "Write review",
    component: WriteReview,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/order-review",
    name: "EmailOrderReview",
    component: EmailOrderReview,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/successmessage",
    name: "Success Message",
    component: successMessage,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/successmessagewritereview",
    name: "Success Message",
    component: successMessageWriteReview,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/errormessage",
    name: "Error Message",
    component: errorMessage,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/update-product-review",
    name: "UpdateProductReview",
    component: UpdateProductReview,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/update-shop-review",
    name: "UpdateShopReview",
    component: UpdateShopReview,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
  {
    path: "/social-share",
    name: "SocialMetaData",
    component: SocialMetaData,
    parentname: "",
    invisible: true,
    select: true,
    layout: "/storefront",
    isAccessRequired: false
  },
]
export default dashRoutes;