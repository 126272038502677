import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class SocialMetaData extends Component {
    render() {
        return (
            <Helmet>
                <meta property="og:title" content="5 Panel Camp Cap rated ⭐ 3/5 by Shane M." />
                <meta property="og:description" content="Legit Product!!! Noise Cancellation is working jus" />
                <meta name="twitter:card" content="summary" />
                <meta property="twitter:title" content="5 Panel Camp Cap rated ⭐ 3/5 by Shane M." />
                <meta property="twitter:description" content="Legit Product!!! Noise Cancellation is working jus" />
                <meta property="og:type" content="website" />
                <meta http-equiv="content-language" content="en-in" />
                <meta property="og:image" content="https://app.shynr.co/media/6148512ff35709.jpg" />
                <meta property="twitter:image" content="https://app.shynr.co/media/6148512ff35709.jpg" />
            </Helmet>
        )
    }
}
