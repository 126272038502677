import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../src/assets/css/style.scss';
import '../src/assets/css/email-template.scss';
import '../src/assets/css/widget.scss';
import '../src/assets/css/iframe-widget.scss';
import 'flag-icon-css/css/flag-icon.css'
import '@shopify/polaris/dist/styles.css';
import reportWebVitals from './reportWebVitals';
// import { createBrowserHistory } from "history";
import { BrowserRouter , Route, Switch, Redirect } from "react-router-dom";
import StoreFrontLayout from "./layouts/StoreFront.js";
// const history = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter>
    <div>
    <Switch>
      <Route path="/storefront" component={StoreFrontLayout} />
      <Redirect from="/" to="/storefront/productreview" />
    </Switch>
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);
reportWebVitals();

